import axios from '@/libs/axios'

export default {
  // Document settings
  addDocumentSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `document-settings/`, method: 'POST', data: params})
        .then(res => {
          commit('ADD_DOCUMENT_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getDocumentSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `document-settings/`, method: 'GET', params})
        .then(res => {
          commit('SET_DOCUMENT_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editDocumentSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: `document-settings/${item.id}/`, method: 'PATCH', data: item})
        .then(res => {
          commit('EDIT_DOCUMENT_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getInsuranceDocumentTypes() {
    return new Promise((resolve, reject) => {
      axios({url: `insurance-document-type/`, method: 'GET'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  editInsuranceDocumentTypeSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `document-settings/${params.id}/insurance-document-type-settings`, method: 'PATCH', data: params.data
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Patient reception schedule settings
  addPatientReceptionScheduleSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `patient-reception-schedule-settings/`, method: 'POST', data: params})
        .then(res => {
          commit('ADD_PATIENT_RECEPTION_SCHEDULE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getPatientReceptionScheduleSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `patient-reception-schedule-settings/`, method: 'GET', params})
        .then(res => {
          commit('SET_PATIENT_RECEPTION_SCHEDULE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editPatientReceptionScheduleSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: `patient-reception-schedule-settings/${item.id}/`, method: 'PATCH', data: item})
        .then(res => {
          commit('EDIT_PATIENT_RECEPTION_SCHEDULE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Cash register settings
  addCashRegisterSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `cash-register-settings/`, method: 'POST', data: params})
        .then(res => {
          commit('ADD_CASH_REGISTER_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getCashRegisterSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `cash-register-settings/`, method: 'GET', params})
        .then(res => {
          commit('SET_CASH_REGISTER_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editCashRegisterSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: `cash-register-settings/${item.id}/`, method: 'PATCH', data: item})
        .then(res => {
          commit('EDIT_CASH_REGISTER_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Integrator system modules settings
  fetchIntegratorSystemModulesSettings({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({url: `integrator/${id}/system-module-setting`, method: 'GET'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  saveIntegratorSystemModulesSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `integrator-system-module-setting/`, method: 'POST', data: params})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Auth group system modules settings
  fetchAuthGroupSystemModulesSettings({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({url: `auth-group/${id}/system-module-setting`, method: 'GET'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Price settings
  addPriceSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `price-settings/`, method: 'POST', data: params})
        .then(res => {
          commit('ADD_PRICE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getPriceSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `price-settings/`, method: 'GET', params})
        .then(res => {
          commit('SET_PRICE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editPriceSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: `price-settings/${item.id}/`, method: 'PATCH', data: item})
        .then(res => {
          commit('EDIT_PRICE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  priceSettingsRecalculateNonRegulatedServices({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `price-settings/recalculate-non-regulated-services`, method: 'POST', data: params})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  priceSettingsRecalculateRegulatedServices({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `price-settings/recalculate-regulated-services`, method: 'POST', data: params})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Treatment diary settings
  addTreatmentDiarySettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `treatment-diary-settings/`, method: 'POST', data: params})
        .then(res => {
          commit('ADD_TREATMENT_DIARY_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getTreatmentDiarySettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `treatment-diary-settings/`, method: 'GET', params})
        .then(res => {
          commit('SET_TREATMENT_DIARY_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editTreatmentDiarySettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: `treatment-diary-settings/${item.id}/`, method: 'PATCH', data: item})
        .then(res => {
          commit('EDIT_TREATMENT_DIARY_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getTreatmentDiaryDocumentTypes() {
    return new Promise((resolve, reject) => {
      axios({url: `treatment-diary-document-type/`, method: 'GET'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  editTreatmentDiaryDocumentTypeSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `treatment-diary-settings/${params.id}/document-type-settings`, method: 'PATCH', data: params.data})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Patient card settings
  addPatientCardSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `patient-card-settings/`, method: 'POST', data: params})
        .then(res => {
          commit('ADD_PATIENT_CARD_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getPatientCardSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `patient-card-settings/`, method: 'GET', params})
        .then(res => {
          commit('SET_PATIENT_CARD_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editPatientCardSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: `patient-card-settings/${item.id}/`, method: 'PATCH', data: item})
        .then(res => {
          commit('EDIT_PATIENT_CARD_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Employee settings
  addEmployeeSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `employee-settings/`, method: 'POST', data: params})
        .then(res => {
          commit('ADD_EMPLOYEE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getEmployeeSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `employee-settings/`, method: 'GET', params})
        .then(res => {
          commit('SET_EMPLOYEE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editEmployeeSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: `employee-settings/${item.id}/`, method: 'PATCH', data: item})
        .then(res => {
          commit('EDIT_EMPLOYEE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Dental service settings
  addDentalServiceSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `dental-service-settings/`, method: 'POST', data: params})
        .then(res => {
          commit('ADD_DENTAL_SERVICE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getDentalServiceSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `dental-service-settings/`, method: 'GET', params})
        .then(res => {
          commit('SET_DENTAL_SERVICE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editDentalServiceSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: `dental-service-settings/${item.id}/`, method: 'PATCH', data: item})
        .then(res => {
          commit('EDIT_DENTAL_SERVICE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Treatment presentation settings
  addTreatmentPresentationSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `treatment-presentation-settings/`, method: 'POST', data: params})
        .then(res => {
          commit('ADD_TREATMENT_PRESENTATION_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getTreatmentPresentationSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `treatment-presentation-settings/`, method: 'GET', params})
        .then(res => {
          commit('SET_TREATMENT_PRESENTATION_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editTreatmentPresentationSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: `treatment-presentation-settings/${item.id}/`, method: 'PATCH', data: item})
        .then(res => {
          commit('EDIT_TREATMENT_PRESENTATION_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Lead settings
  addLeadSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `lead-settings/`, method: 'POST', data: params})
        .then(res => {
          commit('ADD_LEAD_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getLeadSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `lead-settings/`, method: 'GET', params})
        .then(res => {
          commit('SET_LEAD_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editLeadSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: `lead-settings/${item.id}/`, method: 'PATCH', data: item})
        .then(res => {
          commit('EDIT_LEAD_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  }
}
