import store from '@/store'
import { mixins } from '@/mixins'
import { computed } from 'vue'

export default function useSocket() {
  const canConnectToSocket = computed(() => store.getters['auth/canConnectToSocket'])
  const connectToUserSocket = () => {
    if (!canConnectToSocket.value) {
      return
    }

    const user = mixins.methods.getUser()
    if (user && user.id) {
      let ws = new WebSocket(process.env.VUE_APP_WS_HOST + '/ws/user/' + user.id)
      ws.onopen = () => {
        console.log('client connected to user socket')
        store.commit('auth/SET_USER_SOCKET_CONNECTION', ws)
      }

      ws.onmessage = async e => {
        const data = e.data ? JSON.parse(e.data) : null
        await mixins.methods.processUserNotification(data)
      }

      ws.onclose = e=> {
        if (e.code) {
          if (e.code === 1000) {
            console.log('User socket is closed. Client logged out.')
          } else {
            console.log('User socket is closed. Reconnect will be attempted in 10 seconds.', e.reason)
            setTimeout(() => connectToUserSocket(), 10000)
          }
          store.commit('auth/CLEAR_USER_SOCKET_CONNECTION')
        }
      }

      ws.onerror = e=> {
        console.error('Socket encountered error: ', e.message, 'Closing socket')
        store.commit('auth/CLEAR_USER_SOCKET_CONNECTION')
      }

      setTimeout(() => {
        if (ws.readyState !== 1) {
          console.log('close socket by timeout')
          ws.close()
        }
      }, 1000)
    }
  }
  const connectToIntegratorSocket = () => {
    if (!canConnectToSocket.value) {
      return
    }

    if (!store.getters['auth/getIntegratorSocketConnection']) {
      const user = mixins.methods.getUser()
      if (user && user.id) {
        let ws = new WebSocket(process.env.VUE_APP_WS_HOST + '/ws/integrator/' + user.integrator_id)
        ws.onopen = () => {
          console.log('client connected to integrator socket')
          store.commit('auth/SET_INTEGRATOR_SOCKET_CONNECTION', ws)
        }

        ws.onmessage = e=> {
          const data = e.data ? JSON.parse(e.data) : null
          const message = data && data.message ? data.message : null
          const messageActionType = data && data.msg_action_type ? data.msg_action_type : null
          if (messageActionType === 'patient-appointment-changes' && user.id !== message.creator_id) {
            store.commit('schedule/SET_NEW_PATIENT_APPOINTMENT_INFO', message)
          }
        }

        ws.onclose = e => {
          if (e.code) {
            if (e.code === 1000) {
              console.log('Integrator socket is closed. Client logged out.')
            } else {
              console.log('Integrator socket is closed. Reconnect will be attempted in 10 seconds.', e.reason)
              setTimeout(() => connectToIntegratorSocket(), 10000)
            }
            store.commit('auth/CLEAR_INTEGRATOR_SOCKET_CONNECTION')
          }
        }

        ws.onerror = e=> {
          console.error('Socket encountered error: ', e.message, 'Closing socket')
          store.commit('auth/CLEAR_INTEGRATOR_SOCKET_CONNECTION')
        }

        setTimeout(() => {
          if (ws.readyState !== 1) {
            console.log('close socket by timeout')
            ws.close()
          }
        }, 1000)
      }
    }
  }

  return {
    connectToUserSocket,
    connectToIntegratorSocket
  }
}