import { pdfMixins } from '@/pdf-mixins'
import store from '@/store'
import { mixins } from '@/mixins'
import { jsPDF } from 'jspdf'
import moment from 'moment/moment'
import appConstants from '@/constants/constants'
import {
  actContractNumberHidden, actFillSignerDoctorName, purchaseOrderAdministratorHidden, purchaseOrderExecutorLabel,
  purchaseOrderFillExecutorDoctorName,
} from '@/composables/documentSettingsHelper'
import { getPaymentDiscountAmountByTreatmentDiaryServices } from '@/composables/priceCalculationHelper'
import { getPurchaseOrderInfo } from '@/composables/dentalLaboratory'
import { saveExcelFileFromResponse } from '@/composables/fileHelper'
import { showCommonToast, showFailedOperationToast } from '@/composables/toast'
import { getIntegratorId } from '@/composables/currentUser'
import usePatient from '@/composables/patientHelper'
import useTreatmentDiary from '@/composables/treatment-diary'
import useIntegrator from '@/composables/integrator'
import stampSrc from '@/assets/images/integrator/stamp.png'

export const textToRows = (
  text, prevText, pdf, pdfMargin, pdfPosY, pdfFont, withoutLine = false, lineHeightFactor = 2,
  pdfRightMargin = null
) => {
  const pdfWidth = pdf.internal.pageSize.width
  const scaleFactor = pdf.internal.scaleFactor
  let prevTextSize = getTextWidth(pdf, prevText)
  if (text) {
    const pdfHeight = pdf.internal.pageSize.height
    text = text.trim()
    let phrases = []
    if (/\n/.exec(text)) {
      phrases = text.split("\n")
    } else {
      phrases.push(text)
    }
    pdf.setFont(pdfFont)
    phrases.map((phrase, phraseIndex) => {
      phrase = phrase.trim()
      prevTextSize = phraseIndex ? 0 : prevTextSize + 2
      pdfRightMargin = pdfRightMargin ? pdfRightMargin : pdfMargin
      const textMargin = pdfMargin + pdfRightMargin
      pdf.setLineHeightFactor(lineHeightFactor)
      if (getTextWidth(pdf, phrase) + textMargin + prevTextSize > pdfWidth) {
        const splittedText = pdf.splitTextToSize(phrase, pdfWidth - textMargin - prevTextSize)
        pdf.text(splittedText[0], pdfMargin + prevTextSize, pdfPosY)
        if (!withoutLine) {
          pdfPosY += 1
          pdf.line(pdfMargin + prevTextSize, pdfPosY, pdfWidth - pdfRightMargin, pdfPosY)
        }
        let otherText = splittedText.slice(1)
        otherText = pdf.splitTextToSize(otherText.join(' '), pdfWidth - textMargin)
        const otherTextLength = otherText.length
        let counter = 0
        while (counter < otherTextLength) {
          const height = pdf.getLineHeight(otherText[counter]) / scaleFactor
          if (pdfHeight < pdfPosY + pdfMargin + height) {
            pdf.addPage()
            pdfPosY = 10
            pdf.setLineHeightFactor(lineHeightFactor)
          }
          if (!counter) {
            pdfPosY += height
          }
          pdf.text(otherText[counter], pdfMargin, pdfPosY)
          if (!withoutLine) {
            pdfPosY += 1
            pdf.line(pdfMargin, pdfPosY, pdfWidth - pdfRightMargin, pdfPosY)
          }
          pdfPosY += height
          ++counter
        }
      } else {
        if (pdfHeight < pdfPosY + pdfMargin + (pdf.getLineHeight(phrase) / scaleFactor)) {
          pdf.addPage()
          pdfPosY = 10
          pdf.setLineHeightFactor(lineHeightFactor)
        }
        pdf.text(phrase, prevTextSize ? pdfMargin + prevTextSize : pdfMargin, pdfPosY)
        if (!withoutLine) {
          pdfPosY += 1
          pdf.line(prevTextSize ? pdfMargin + prevTextSize : pdfMargin, pdfPosY, pdfWidth - pdfRightMargin, pdfPosY)
        }
        pdfPosY += pdf.getLineHeight(phrase) / scaleFactor
      }
    })
  } else if (prevText) {
    if (!withoutLine) {
      pdfPosY += 1
      pdf.line(prevTextSize ? pdfMargin + prevTextSize + 2 : pdfMargin, pdfPosY, pdfWidth - pdfRightMargin, pdfPosY)
    }
    pdfPosY += pdf.getLineHeight('-') / scaleFactor
  }
  return pdfPosY
}

export const textToLines = (
  text, prevText, pdf, pdfMargin, pdfPosY, pdfFont, withoutLine = false, scaleFactor = null,
  useParagraph = true, pdfMarginRight = null
) => {
  const pdfWidth = pdf.internal.pageSize.width
  scaleFactor = scaleFactor ? scaleFactor : pdf.internal.scaleFactor
  let prevTextSize = getTextWidth(pdf, prevText)
  if (text) {
    const pdfHeight = pdf.internal.pageSize.height
    text = text.trim()
    let phrases = []
    if (/\n/.exec(text)) {
      phrases = text.split("\n")
    } else {
      phrases.push(text)
    }
    pdf.setFont(pdfFont)
    phrases.map((phrase, phraseIndex) => {
      phrase = phrase.trim()
      prevTextSize = phraseIndex || !useParagraph ? 0 : (prevTextSize + 2)
      const textMargin = pdfMarginRight ? pdfMargin + pdfMarginRight : (pdfMargin * 2)
      pdf.setLineHeightFactor(2)
      if (getTextWidth(pdf, phrase) + textMargin + prevTextSize > pdfWidth) {
        const splittedText = pdf.splitTextToSize(phrase, pdfWidth - textMargin - prevTextSize)
        pdf.text(splittedText[0], pdfMargin + prevTextSize, pdfPosY)
        if (!withoutLine) {
          pdfPosY += 1
          pdf.line(pdfMargin + prevTextSize, pdfPosY, pdfWidth - pdfMargin, pdfPosY)
        }
        let otherText = splittedText.slice(1)
        otherText = pdf.splitTextToSize(otherText.join(' '), pdfWidth - textMargin)
        const otherTextLength = otherText.length
        let counter = 0
        while (counter < otherTextLength) {
          const height = pdf.getTextDimensions(otherText[counter]).h + scaleFactor
          if (pdfHeight < pdfPosY + 10 + height) {
            pdf.addPage()
            pdfPosY = 10
            pdf.setLineHeightFactor(2)
          }
          if (!counter) {
            pdfPosY += height
          }
          pdf.text(otherText[counter], pdfMargin, pdfPosY)
          if (!withoutLine) {
            pdfPosY += 1
            pdf.line(pdfMargin, pdfPosY, pdfWidth - pdfMargin, pdfPosY)
          }
          pdfPosY += height
          ++counter
        }
      } else {
        if (pdfHeight < pdfPosY + 10 + pdf.getTextDimensions(phrase).h + scaleFactor) {
          pdf.addPage()
          pdfPosY = 10
          pdf.setLineHeightFactor(2)
        }
        pdf.text(phrase, prevTextSize ? pdfMargin + prevTextSize : pdfMargin, pdfPosY)
        if (!withoutLine) {
          pdfPosY += 1
          pdf.line(prevTextSize ? pdfMargin + prevTextSize : pdfMargin, pdfPosY, pdfWidth - pdfMargin, pdfPosY)
        }
        pdfPosY += pdf.getTextDimensions(phrase).h + scaleFactor
      }
    })
  } else if (prevText) {
    if (!withoutLine) {
      pdfPosY += 1
      pdf.line(prevTextSize ? pdfMargin + prevTextSize + 2 : pdfMargin, pdfPosY, pdfWidth - pdfMargin, pdfPosY)
    }
    pdfPosY += pdf.getTextDimensions('-').h + scaleFactor
  }
  return pdfPosY
}

export const createActOfServicesRenderedForPurchaseOrder = async purchaseOrderId => {
  let purchaseOrder = null
  await getPurchaseOrderInfo(purchaseOrderId).then(({ data }) => purchaseOrder = data)
  if (!purchaseOrder) {
    showFailedOperationToast(
      'Ошибка формирования акта оказанных услуг. Пожалуйста, обратитесь в службу технической поддержки'
    )
    return
  }
  const dentalLaboratory = purchaseOrder.dental_laboratory
  const client = purchaseOrder.client
  const patient = purchaseOrder.patient
  const pdfFontNormal = 'OpenSans-Regular'
  const pdfFontBold = 'OpenSans-Bold'
  const pdfMargin = 8
  const pdfFontSize = 10
  const pdfRowHeight = 7
  const pdfWidth = 210
  const pdfHeight = 297

  let pdfCurrentPositionY = 0
  let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

  // header
  pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, dentalLaboratory, pdfFontNormal, pdfMargin)
  pdfCurrentPositionY = mixins.methods.getPositionAfterHeader(dentalLaboratory, 42, false)

  pdfFile.setFontSize(pdfFontSize)
  pdfFile.setFont(pdfFontBold)
  mixins.methods.setActTitle(pdfFile, dentalLaboratory, purchaseOrder, pdfWidth, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)

  if (dentalLaboratory.city) {
    pdfCurrentPositionY += pdfRowHeight
    pdfFile.text(
      'Место составления ' + dentalLaboratory.city, pdfWidth - pdfMargin, pdfCurrentPositionY, 'right'
    )
  }

  pdfCurrentPositionY += (pdfRowHeight * 2)

  let rightDataPosition = pdfWidth / 5
  pdfFile.setFont(pdfFontBold)
  pdfFile.text('Дата:', pdfMargin, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)

  let stringDate = moment(parseInt(purchaseOrder.act_creation_date)).format('L')
  pdfFile.text(stringDate, rightDataPosition, pdfCurrentPositionY)
  pdfFile.line(
    rightDataPosition,
    pdfCurrentPositionY + 1,
    rightDataPosition + getTextWidth(pdfFile, stringDate),
    pdfCurrentPositionY + 1,
  )

  if (!actContractNumberHidden()) {
    pdfFile.setFont(pdfFontBold)
    let actText = 'Договор №:  '
    pdfFile.text(actText, pdfWidth / 2, pdfCurrentPositionY, 'center')
    pdfFile.setFont(pdfFontNormal)
    const contractNumber = mixins.methods.getActContractNumber(purchaseOrder, patient)
    const contractNumberWidth = getTextWidth(pdfFile, contractNumber)
    const actTextWidth = getTextWidth(pdfFile, actText)
    pdfFile.text(contractNumber, (pdfWidth / 2) + (actTextWidth / 2) + 1, pdfCurrentPositionY)
    pdfFile.line(
      (pdfWidth / 2) + (actTextWidth / 2),
      pdfCurrentPositionY + 1,
      (pdfWidth / 2) + ((actTextWidth / 2) + contractNumberWidth + 2),
      pdfCurrentPositionY + 1
    )
  }

  pdfFile.setFont(pdfFontBold)
  pdfCurrentPositionY += pdfRowHeight

  pdfFile.text('Заказчик:', pdfMargin, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(client.name ? client.name : '', rightDataPosition, pdfCurrentPositionY)
  pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

  if (client.address) {
    pdfFile.setFont(pdfFontBold)
    pdfCurrentPositionY += pdfRowHeight

    pdfFile.text('Адрес:', pdfMargin, pdfCurrentPositionY)

    pdfFile.setFont(pdfFontNormal)
    pdfFile.text(client.address, rightDataPosition, pdfCurrentPositionY)
    pdfFile.line(
      rightDataPosition,
      pdfCurrentPositionY + 1,
      pdfWidth - pdfMargin,
      pdfCurrentPositionY + 1
    )
  }
  pdfCurrentPositionY += pdfRowHeight

  if (purchaseOrder.doctor) {
    pdfFile.setFont(pdfFontBold)
    pdfFile.text('Ф.И.О врача:', pdfMargin, pdfCurrentPositionY)
    pdfFile.setFont(pdfFontNormal)
    pdfFile.text(purchaseOrder.doctor.full_name + '', rightDataPosition, pdfCurrentPositionY)
    pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
    pdfCurrentPositionY += pdfRowHeight
  }
  pdfCurrentPositionY += pdfRowHeight

  // Table services title
  const title = getServicesTableTitleForActOfServicesRendered(dentalLaboratory.name)
  pdfCurrentPositionY = textToRows(
    title, null, pdfFile, pdfMargin, pdfCurrentPositionY, pdfFontNormal, true
  )
  pdfFile.setLineHeightFactor(1)

  let rows = [
    [
      {content: 'Номер', styles: {halign: 'center'}},
      {content: 'Наименование', styles: {halign: 'center'}},
      {content: 'Кол', styles: {halign: 'center'}},
      {content: 'Цена', styles: {halign: 'center'}},
      {content: 'Сумма', styles: {halign: 'center'}}
    ]
  ]

  purchaseOrder.services_history.forEach(s => {
    rows.push([
      {content: s.number, styles: {halign: 'center'}},
      {content: s.name},
      {content: s.count, styles: {halign: 'center'}},
      {content: mixins.methods.formatPrice(s.total_price, true), styles: {halign: 'center'}},
      {content: mixins.methods.formatPrice(s.total_price * s.count, true), styles: {halign: 'center'}}
    ])
  })
  let totalPrice = purchaseOrder.services_history.reduce(
    (total, s) => total + parseFloat(mixins.methods.formatPrice(s.total_price * s.count, false)), 0
  )
  totalPrice = parseFloat(mixins.methods.formatPrice(totalPrice, false))
  const discountAmount = purchaseOrder.services_history.length
    ? getPaymentDiscountAmountByTreatmentDiaryServices(
      purchaseOrder.services_history, purchaseOrder.calc_discount_percent
    )
    : (purchaseOrder.calc_discount_percent / 100) * totalPrice
  let salePrice = purchaseOrder.calc_discount_percent ? (totalPrice - discountAmount) : totalPrice
  salePrice = salePrice < 0 ? 0 : salePrice
  rows.push([
    {content: 'Итого', styles: {halign: 'left'}, colSpan: 4},
    {content: mixins.methods.formatPrice(totalPrice, true), styles: { halign: 'center' }}
  ])
  pdfFile.autoTable({
    theme: 'grid',
    startY: pdfCurrentPositionY,
    body: rows,
    bodyStyles: {
      cellPadding: 1,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0]
    },
    styles: {
      font: pdfFontNormal,
      fontSize: pdfFontSize,
    },
    didParseCell: hook => {
      if (hook.row.index === 0) {
        hook.cell.styles.font = pdfFontBold
      }
    },
    didDrawPage: hook => {
      pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
    }
  })

  if (pdfHeight - (pdfRowHeight * 7) < pdfCurrentPositionY) {
    pdfFile.addPage()
    pdfCurrentPositionY = pdfRowHeight * 2
  } else {
    pdfCurrentPositionY += pdfRowHeight
  }

  pdfFile.text(
    'Вышеперечисленные услуги выполнены полностью. Заказчик по объему, качеству и срокам оказания услуг',
    pdfMargin,
    pdfCurrentPositionY
  )
  pdfCurrentPositionY += pdfRowHeight
  pdfFile.text(
    'претензий не имеет. Настоящий акт является основанием к оплате оказанных услуг.',
    pdfMargin,
    pdfCurrentPositionY
  )
  pdfCurrentPositionY += (pdfRowHeight * 2)

  pdfFile.setFont(pdfFontBold)
  pdfFile.text('ИТОГО', pdfMargin, pdfCurrentPositionY)
  pdfCurrentPositionY += pdfRowHeight

  let totalText = 'Начислено:   '
  pdfFile.text(totalText, pdfMargin, pdfCurrentPositionY)

  pdfFile.setFont(pdfFontNormal)

  pdfFile.text(
    mixins.methods.formatPrice(totalPrice, true), pdfMargin + getTextWidth(pdfFile, totalText),
    pdfCurrentPositionY
  )

  pdfFile.setFont(pdfFontBold)
  let discountText = 'Скидка %:   '
  pdfFile.text(discountText, (pdfWidth / 4) + 4, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(
    purchaseOrder.calc_discount_percent ? purchaseOrder.calc_discount_percent.toString() : '0',
    (pdfWidth / 4) + 4 + getTextWidth(pdfFile, discountText),
    pdfCurrentPositionY
  )

  pdfFile.setFont(pdfFontBold)
  let payText = 'К оплате:   '
  pdfFile.text(payText, pdfWidth / 2, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(
    (salePrice > 0 && totalPrice) || purchaseOrder.calc_discount_percent === 100
      ? mixins.methods.formatPrice(salePrice, true)
      : mixins.methods.formatPrice(totalPrice, true),
    (pdfWidth / 2) + getTextWidth(pdfFile, payText),
    pdfCurrentPositionY
  )

  pdfCurrentPositionY += (pdfRowHeight * 2)
  pdfFile.setFont(pdfFontBold)
  pdfFile.setDrawColor(0,0,0,1)
  let cashierText = mixins.methods.getSignerLabelForAct()
  pdfFile.text(cashierText, pdfMargin, pdfCurrentPositionY)
  const offset = pdfMargin + pdfFile.getTextWidth(cashierText)
  if (actFillSignerDoctorName() && purchaseOrder.doctor) {
    pdfFile.setFont(pdfFontNormal)
    pdfFile.text(purchaseOrder.doctor.full_name_initials, offset, pdfCurrentPositionY)
    pdfFile.setFont(pdfFontBold)
  }
  pdfFile.line(offset, pdfCurrentPositionY + 1, offset + 40, pdfCurrentPositionY + 1)

  let clientText = 'Заказчик: '
  pdfFile.text(clientText, pdfWidth / 2, pdfCurrentPositionY)

  pdfFile.line(
    (pdfWidth / 2) + getTextWidth(pdfFile, clientText),
    pdfCurrentPositionY + 1,
    pdfWidth - pdfMargin,
    pdfCurrentPositionY + 1,
  )

  pdfFile.save(mixins.methods.getActTitle(purchaseOrder) + ' - ' + client.name + '.pdf')
}

export const createActOfComplexServicesRenderedForPurchaseOrder = async purchaseOrderId => {
  let purchaseOrder = null
  await getPurchaseOrderInfo(purchaseOrderId).then(({ data }) => purchaseOrder = data)
  if (!purchaseOrder) {
    showFailedOperationToast(
      'Ошибка формирования акта оказанных комплексов услуг. Пожалуйста, обратитесь в службу технической поддержки'
    )
    return
  }
  const dentalLaboratory = purchaseOrder.dental_laboratory
  const patient = purchaseOrder.patient
  const pdfFontNormal = 'OpenSans-Regular'
  const pdfFontBold = 'OpenSans-Bold'
  const pdfMargin = 8
  const pdfFontSize = 10
  const pdfRowHeight = 7
  const pdfWidth = 210
  const pdfHeight = 297

  let pdfCurrentPositionY = 0
  let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

  // header
  pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, dentalLaboratory, pdfFontNormal, pdfMargin)
  pdfCurrentPositionY = mixins.methods.getPositionAfterHeader(dentalLaboratory, 42, false)

  pdfFile.setFontSize(pdfFontSize)
  let patientName = patient.full_name

  pdfFile.setFont(pdfFontBold)
  mixins.methods.setActTitle(pdfFile, dentalLaboratory, purchaseOrder, pdfWidth, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)

  if (dentalLaboratory.city) {
    pdfCurrentPositionY += pdfRowHeight
    pdfFile.text(
      'Место составления ' + dentalLaboratory.city, pdfWidth - pdfMargin, pdfCurrentPositionY, 'right'
    )
  }

  pdfCurrentPositionY += (pdfRowHeight * 2)

  let rightDataPosition = pdfWidth / 5
  pdfFile.setFont(pdfFontBold)
  pdfFile.text('Дата:', pdfMargin, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)

  let stringDate = moment(parseInt(purchaseOrder.act_creation_date)).format('L')
  pdfFile.text(stringDate, rightDataPosition, pdfCurrentPositionY)
  pdfFile.line(
    rightDataPosition,
    pdfCurrentPositionY + 1,
    rightDataPosition + getTextWidth(pdfFile, stringDate),
    pdfCurrentPositionY + 1,
  )

  if (!actContractNumberHidden()) {
    pdfFile.setFont(pdfFontBold)
    let actText = 'Договор №:  '
    pdfFile.text(actText, pdfWidth / 2, pdfCurrentPositionY, 'center')
    pdfFile.setFont(pdfFontNormal)
    const contractNumber = mixins.methods.getActContractNumber(purchaseOrder, patient)
    const contractNumberWidth = getTextWidth(pdfFile, contractNumber)
    const actTextWidth = getTextWidth(pdfFile, actText)
    pdfFile.text(contractNumber, (pdfWidth / 2) + (actTextWidth / 2) + 1, pdfCurrentPositionY)
    pdfFile.line(
      (pdfWidth / 2) + (actTextWidth / 2),
      pdfCurrentPositionY + 1,
      (pdfWidth / 2) + ((actTextWidth / 2) + contractNumberWidth + 2),
      pdfCurrentPositionY + 1
    )
  }

  pdfFile.setFont(pdfFontBold)
  pdfCurrentPositionY += pdfRowHeight

  pdfFile.text('Заказчик:', pdfMargin, pdfCurrentPositionY)

  pdfFile.setFont(pdfFontNormal)

  pdfFile.text(patientName, rightDataPosition, pdfCurrentPositionY)
  pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

  pdfFile.setFont(pdfFontBold)
  pdfCurrentPositionY += pdfRowHeight

  pdfFile.text('Адрес:', pdfMargin, pdfCurrentPositionY)

  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(patient.address ? patient.address : '', rightDataPosition, pdfCurrentPositionY)
  pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
  pdfCurrentPositionY += pdfRowHeight

  if (purchaseOrder.doctor) {
    pdfFile.setFont(pdfFontBold)
    pdfFile.text('Ф.И.О врача:', pdfMargin, pdfCurrentPositionY)
    pdfFile.setFont(pdfFontNormal)
    pdfFile.text(purchaseOrder.doctor.full_name + '', rightDataPosition, pdfCurrentPositionY)
    pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
    pdfCurrentPositionY += pdfRowHeight
  }
  pdfCurrentPositionY += pdfRowHeight

  // Table services title
  const title = getServicesTableTitleForActOfServicesRendered(dentalLaboratory.name)
  pdfCurrentPositionY = textToRows(
    title, null, pdfFile, pdfMargin, pdfCurrentPositionY, pdfFontNormal, true
  )
  pdfFile.setLineHeightFactor(1)

  const additionalServices = []
  const treatmentPlansTableData = {}
  purchaseOrder.services_history.map(s => {
    if (s.complex) {
      const complex = s.complex
      if (complex.id in treatmentPlansTableData) {
        treatmentPlansTableData[complex.id].amount +=
          parseFloat(mixins.methods.formatPrice(s.total_price * s.count, false))
      } else {
        treatmentPlansTableData[complex.id] = {
          number: complex.number,
          name: complex.name,
          count: complex.count,
          price: complex.price,
          amount: parseFloat(mixins.methods.formatPrice(s.total_price * s.count, false))
        }
      }
    } else {
      additionalServices.push(s)
    }
  })

  pdfCurrentPositionY += pdfRowHeight
  pdfFile.text('Комплексы услуг:', pdfMargin, pdfCurrentPositionY)

  const tablesHeader = [
    {content: 'Номер', styles: {halign: 'center'}},
    {content: 'Наименование', styles: {halign: 'center'}},
    {content: 'Количество', styles: {halign: 'center'}},
    {content: 'Цена', styles: {halign: 'center'}},
    {content: 'Сумма', styles: {halign: 'center'}}
  ]
  let rows = [tablesHeader]

  let totalPlansPrice = 0
  Object.values(treatmentPlansTableData).map(plan => {
    totalPlansPrice += plan.amount
    rows.push([
      {content: plan.number, styles: {halign: 'center'}},
      {content: plan.name, styles: {halign: 'left'}},
      {content: plan.count, styles: {halign: 'center'}},
      {content: mixins.methods.formatPrice(plan.price, true), styles: {halign: 'center'}},
      {content: mixins.methods.formatPrice(plan.amount, true), styles: {halign: 'center'}},
    ])
  })

  rows.push([
    {content: 'Итого', styles: {halign: 'left'}, colSpan: 4},
    {
      content: mixins.methods.formatPrice(totalPlansPrice, true), styles: { halign: 'center' }
    }
  ])
  let totalPrice = purchaseOrder.services_history.reduce(
    (total, s) => total + parseFloat(mixins.methods.formatPrice(s.total_price * s.count, false)), 0
  )
  totalPrice = parseFloat(mixins.methods.formatPrice(totalPrice, false))
  const discountAmount = purchaseOrder.services_history.length
    ? getPaymentDiscountAmountByTreatmentDiaryServices(
      purchaseOrder.services_history, purchaseOrder.calc_discount_percent
    )
    : (purchaseOrder.calc_discount_percent / 100) * totalPrice
  let salePrice = purchaseOrder.calc_discount_percent ? (totalPrice - discountAmount) : totalPrice
  salePrice = salePrice < 0 ? 0 : salePrice
  pdfFile.autoTable({
    theme: 'grid',
    startY: pdfCurrentPositionY + (pdfRowHeight / 2),
    body: rows,
    bodyStyles: {
      cellPadding: 1,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0]
    },
    styles: {
      font: pdfFontNormal,
      fontSize: pdfFontSize,
    },
    didParseCell: hook => {
      if (hook.row.index === 0) {
        hook.cell.styles.font = pdfFontBold
      }
    },
    didDrawPage: hook => {
      pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
    }
  })

  if (additionalServices.length) {
    pdfFile.text('Дополнительные услуги:', pdfMargin, pdfCurrentPositionY)
    rows = [tablesHeader]

    let totalServicesPrice = 0
    additionalServices.map(s => {
      totalServicesPrice += s.total_price * s.count
      rows.push([
        {content: s.number, styles: {halign: 'center'}},
        {content: s.name},
        {content: s.count, styles: {halign: 'center'}},
        {content: mixins.methods.formatPrice(s.total_price, true), styles: {halign: 'center'}},
        {
          content: mixins.methods.formatPrice(s.total_price * s.count, true),
          styles: {halign: 'center'}
        }
      ])
    })

    rows.push([
      {content: 'Итого', styles: {halign: 'left'}, colSpan: 4},
      {content: mixins.methods.formatPrice(totalServicesPrice, true), styles: { halign: 'center' }}
    ])

    pdfFile.autoTable({
      theme: 'grid',
      startY: pdfCurrentPositionY + (pdfRowHeight / 2),
      body: rows,
      bodyStyles: {
        cellPadding: 1,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0]
      },
      styles: {
        font: pdfFontNormal,
        fontSize: pdfFontSize,
      },
      didParseCell: hook => {
        if (hook.row.index === 0) {
          hook.cell.styles.font = pdfFontBold
        }
      },
      didDrawPage: hook => {
        pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
      }
    })
  }

  pdfCurrentPositionY += pdfRowHeight
  pdfFile.text(
    'Вышеперечисленные услуги выполнены полностью. Заказчик по объему, качеству и срокам оказания услуг',
    pdfMargin,
    pdfCurrentPositionY
  )
  pdfCurrentPositionY += pdfRowHeight
  pdfFile.text(
    'претензий не имеет. Настоящий акт является основанием к оплате оказанных услуг.',
    pdfMargin,
    pdfCurrentPositionY
  )
  pdfCurrentPositionY += (pdfRowHeight * 2)

  pdfFile.setFont(pdfFontBold)
  pdfFile.text('ИТОГО', pdfMargin, pdfCurrentPositionY)
  pdfCurrentPositionY += pdfRowHeight

  let totalText = 'Начислено:   '
  pdfFile.text(totalText, pdfMargin, pdfCurrentPositionY)

  pdfFile.setFont(pdfFontNormal)

  pdfFile.text(
    mixins.methods.formatPrice(totalPrice, true),
    pdfMargin + getTextWidth(pdfFile, totalText),
    pdfCurrentPositionY
  )

  pdfFile.setFont(pdfFontBold)
  let discountText = 'Скидка %:   '
  pdfFile.text(discountText, (pdfWidth / 4) + 4, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(
    purchaseOrder.calc_discount_percent ? purchaseOrder.calc_discount_percent.toString() : '0',
    (pdfWidth / 4) + 4 + getTextWidth(pdfFile, discountText),
    pdfCurrentPositionY
  )

  pdfFile.setFont(pdfFontBold)
  let payText = 'К оплате:   '
  pdfFile.text(payText, pdfWidth / 2, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(
    (salePrice > 0 && totalPrice) || purchaseOrder.calc_discount_percent === 100
      ? mixins.methods.formatPrice(salePrice, true)
      : mixins.methods.formatPrice(totalPrice, true),
    (pdfWidth / 2) + getTextWidth(pdfFile, payText),
    pdfCurrentPositionY
  )

  pdfCurrentPositionY += (pdfRowHeight * 2)
  pdfFile.setFont(pdfFontBold)
  pdfFile.setDrawColor(0,0,0,1)
  let cashierText = mixins.methods.getSignerLabelForAct()
  pdfFile.text(cashierText, pdfMargin, pdfCurrentPositionY)
  const offset = pdfMargin + pdfFile.getTextWidth(cashierText)
  if (actFillSignerDoctorName() && purchaseOrder.doctor) {
    pdfFile.setFont(pdfFontNormal)
    pdfFile.text(purchaseOrder.doctor.full_name_initials, offset, pdfCurrentPositionY)
    pdfFile.setFont(pdfFontBold)
  }
  pdfFile.line(offset, pdfCurrentPositionY + 1, offset + 40, pdfCurrentPositionY + 1)

  let clientText = 'Заказчик: '
  pdfFile.text(clientText, pdfWidth / 2, pdfCurrentPositionY)

  pdfFile.line(
    (pdfWidth / 2) + getTextWidth(pdfFile, clientText),
    pdfCurrentPositionY + 1,
    pdfWidth - pdfMargin,
    pdfCurrentPositionY + 1,
  )

  pdfFile.save(
    mixins.methods.getActTitle(purchaseOrder) + ' (комплексы услуг) - ' + patient.full_name + '.pdf'
  )
}

export const createActOfServicesRenderedShortForPurchaseOrder = async purchaseOrderId => {
  let purchaseOrder = null
  await getPurchaseOrderInfo(purchaseOrderId).then(({ data }) => purchaseOrder = data)
  if (!purchaseOrder) {
    showFailedOperationToast(
      'Ошибка формирования акта оказанных услуг. Пожалуйста, обратитесь в службу технической поддержки'
    )
    return
  }
  const dentalLaboratory = purchaseOrder.dental_laboratory
  const patient = purchaseOrder.patient
  const pdfFontNormal = 'OpenSans-Regular'
  const pdfFontBold = 'OpenSans-Bold'
  const pdfMargin = 8
  const pdfFontSize = 10
  const pdfRowHeight = 7
  const pdfWidth = 210

  let pdfCurrentPositionY = 0
  let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

  // header
  pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, dentalLaboratory, pdfFontNormal, pdfMargin)
  pdfCurrentPositionY = mixins.methods.getPositionAfterHeader(dentalLaboratory, 42, false)

  pdfFile.setFontSize(pdfFontSize)
  let patientName = patient.full_name

  pdfFile.setFont(pdfFontBold)
  mixins.methods.setActTitle(pdfFile, dentalLaboratory, purchaseOrder, pdfWidth, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)

  if (dentalLaboratory.city) {
    pdfCurrentPositionY += pdfRowHeight
    pdfFile.text(
      'Место составления ' + dentalLaboratory.city, pdfWidth - pdfMargin, pdfCurrentPositionY, 'right'
    )
    pdfCurrentPositionY += pdfRowHeight
  } else {
    pdfCurrentPositionY += (pdfRowHeight * 2)
  }

  let rightDataPosition = pdfWidth / 5
  pdfFile.setFont(pdfFontBold)
  pdfFile.text('Дата:', pdfMargin, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)

  let stringDate = moment(parseInt(purchaseOrder.act_creation_date)).format('L')
  pdfFile.text(stringDate, rightDataPosition, pdfCurrentPositionY)
  pdfFile.line(
    rightDataPosition,
    pdfCurrentPositionY + 1,
    rightDataPosition + getTextWidth(pdfFile, stringDate),
    pdfCurrentPositionY + 1,
  )

  if (!actContractNumberHidden()) {
    pdfFile.setFont(pdfFontBold)
    let actText = 'Договор №:  '
    pdfFile.text(actText, pdfWidth / 2, pdfCurrentPositionY, 'center')
    pdfFile.setFont(pdfFontNormal)
    const contractNumber = mixins.methods.getActContractNumber(purchaseOrder, patient)
    const contractNumberWidth = getTextWidth(pdfFile, contractNumber)
    const actTextWidth = getTextWidth(pdfFile, actText)
    pdfFile.text(contractNumber, (pdfWidth / 2) + (actTextWidth / 2) + 1, pdfCurrentPositionY)
    pdfFile.line(
      (pdfWidth / 2) + (actTextWidth / 2),
      pdfCurrentPositionY + 1,
      (pdfWidth / 2) + ((actTextWidth / 2) + contractNumberWidth + 2),
      pdfCurrentPositionY + 1
    )
  }

  pdfFile.setFont(pdfFontBold)
  pdfCurrentPositionY += pdfRowHeight

  pdfFile.text('Заказчик:', pdfMargin, pdfCurrentPositionY)

  pdfFile.setFont(pdfFontNormal)

  pdfFile.text(patientName, rightDataPosition, pdfCurrentPositionY)
  pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

  pdfFile.setFont(pdfFontBold)
  pdfCurrentPositionY += pdfRowHeight

  pdfFile.text('Адрес:', pdfMargin, pdfCurrentPositionY)

  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(patient.address ? patient.address : '', rightDataPosition, pdfCurrentPositionY)
  pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
  pdfCurrentPositionY += pdfRowHeight

  if (purchaseOrder.doctor) {
    pdfFile.setFont(pdfFontBold)
    pdfFile.text('Ф.И.О врача:', pdfMargin, pdfCurrentPositionY)
    pdfFile.setFont(pdfFontNormal)
    pdfFile.text(purchaseOrder.doctor.full_name + '', rightDataPosition, pdfCurrentPositionY)
    pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
    pdfCurrentPositionY += pdfRowHeight
  }

  // Table services title
  const title = getServicesTableTitleForActOfServicesRendered(dentalLaboratory.name)
  pdfCurrentPositionY = textToLines(
    title, null, pdfFile, pdfMargin, pdfCurrentPositionY, pdfFontNormal, true,
    pdfFile.internal.scaleFactor, false
  )
  pdfFile.setLineHeightFactor(1)
  pdfCurrentPositionY -= 4

  let rows = [[
    {content: 'Услуги', styles: {halign: 'center'}},
    {content: 'Сумма', styles: {halign: 'center'}}
  ]]

  let servicesNumbers = []
  let totalPrice = purchaseOrder.services_history.reduce(
    (totalPrice, s) =>
    {
      servicesNumbers.push(s.number)
      return totalPrice + parseFloat(mixins.methods.formatPrice(s.total_price * s.count, false))
    },
    0
  )
  rows.push([
    {content: servicesNumbers.sort().join(', ')},
    {content: mixins.methods.formatPrice(totalPrice, true), styles: {halign: 'center', valign: 'middle'}}
  ])
  totalPrice = parseFloat(mixins.methods.formatPrice(totalPrice, false))
  const discountAmount = purchaseOrder.services_history.length
    ? getPaymentDiscountAmountByTreatmentDiaryServices(
      purchaseOrder.services_history, purchaseOrder.calc_discount_percent
    )
    : (purchaseOrder.calc_discount_percent / 100) * totalPrice
  let salePrice = purchaseOrder.calc_discount_percent ? (totalPrice - discountAmount) : totalPrice
  salePrice = salePrice < 0 ? 0 : salePrice
  pdfFile.autoTable({
    theme: 'grid',
    startY: pdfCurrentPositionY,
    body: rows,
    margin: {top: 0, right: pdfMargin, bottom: 0, left: pdfMargin},
    bodyStyles: {
      cellPadding: 1,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0]
    },
    styles: {
      font: pdfFontNormal,
      fontSize: pdfFontSize,
    },
    didParseCell: hook => {
      if (hook.row.index === 0) {
        hook.cell.styles.font = pdfFontBold
      }
    },
    didDrawPage: hook => {
      pdfCurrentPositionY = hook.cursor.y
    }
  })

  pdfCurrentPositionY += pdfRowHeight
  pdfFile.text(
    'Вышеперечисленные услуги выполнены полностью. Заказчик по объему, качеству и срокам оказания услуг',
    pdfMargin,
    pdfCurrentPositionY
  )
  pdfCurrentPositionY += pdfRowHeight - 2
  pdfFile.text(
    'претензий не имеет. Настоящий акт является основанием к оплате оказанных услуг.',
    pdfMargin,
    pdfCurrentPositionY
  )
  pdfCurrentPositionY += pdfRowHeight

  pdfFile.setFont(pdfFontBold)
  pdfFile.text('ИТОГО', pdfMargin, pdfCurrentPositionY)
  pdfCurrentPositionY += pdfRowHeight

  let totalText = 'Начислено:   '
  pdfFile.text(totalText, pdfMargin, pdfCurrentPositionY)

  pdfFile.setFont(pdfFontNormal)

  pdfFile.text(
    mixins.methods.formatPrice(totalPrice, true),
    pdfMargin + getTextWidth(pdfFile, totalText),
    pdfCurrentPositionY
  )

  pdfFile.setFont(pdfFontBold)
  let discountText = 'Скидка %:   '
  pdfFile.text(discountText, (pdfWidth / 4) + 4, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(
    purchaseOrder.calc_discount_percent ? purchaseOrder.calc_discount_percent.toString() : '0',
    (pdfWidth / 4) + 4 + getTextWidth(pdfFile, discountText),
    pdfCurrentPositionY
  )

  pdfFile.setFont(pdfFontBold)
  let payText = 'К оплате:   '
  pdfFile.text(payText, pdfWidth / 2, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(
    (salePrice > 0 && totalPrice) || purchaseOrder.calc_discount_percent === 100
      ? mixins.methods.formatPrice(salePrice, true)
      : mixins.methods.formatPrice(totalPrice, true),
    (pdfWidth / 2) + getTextWidth(pdfFile, payText),
    pdfCurrentPositionY
  )

  pdfCurrentPositionY += pdfRowHeight
  pdfFile.setFont(pdfFontBold)
  pdfFile.setDrawColor(0,0,0,1)
  let cashierText = mixins.methods.getSignerLabelForAct()
  pdfFile.text(cashierText, pdfMargin, pdfCurrentPositionY)
  const offset = pdfMargin + pdfFile.getTextWidth(cashierText)
  if (actFillSignerDoctorName() && purchaseOrder.doctor) {
    pdfFile.setFont(pdfFontNormal)
    pdfFile.text(purchaseOrder.doctor.full_name_initials, offset, pdfCurrentPositionY)
    pdfFile.setFont(pdfFontBold)
  }
  pdfFile.line(offset, pdfCurrentPositionY + 1, pdfWidth / 2 - 10, pdfCurrentPositionY + 1)

  let clientText = 'Заказчик: '
  pdfFile.text(clientText, pdfWidth / 2, pdfCurrentPositionY)

  pdfFile.line(
    (pdfWidth / 2) + getTextWidth(pdfFile, clientText),
    pdfCurrentPositionY + 1,
    pdfWidth - pdfMargin,
    pdfCurrentPositionY + 1,
  )

  pdfFile.save(
    mixins.methods.getActTitle(purchaseOrder) + ' (сокращенный формат) - ' + patient.full_name + '.pdf'
  )
}

export const createActOfServicesRenderedWithMaterialsForPurchaseOrder = async purchaseOrderId => {
  let purchaseOrder = null
  await getPurchaseOrderInfo(purchaseOrderId).then(({ data }) => purchaseOrder = data)
  if (!purchaseOrder) {
    showFailedOperationToast(
      'Ошибка формирования акта оказанных услуг. Пожалуйста, обратитесь в службу технической поддержки'
    )
    return
  }
  const dentalLaboratory = purchaseOrder.dental_laboratory
  const patient = purchaseOrder.patient
  const pdfFontNormal = 'OpenSans-Regular'
  const pdfFontBold = 'OpenSans-Bold'
  const pdfMargin = 8
  const pdfFontSize = 10
  const pdfRowHeight = 7
  const pdfWidth = 210
  const pdfHeight = 297
  const priceCalcSystem = mixins.methods.getMaterialPriceCalcSystem()

  let pdfCurrentPositionY = 0
  let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

  // header
  pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, dentalLaboratory, pdfFontNormal, pdfMargin)
  pdfCurrentPositionY = mixins.methods.getPositionAfterHeader(dentalLaboratory, 42, false)

  pdfFile.setFontSize(pdfFontSize)
  let patientName = patient.full_name

  pdfFile.setFont(pdfFontBold)
  mixins.methods.setActTitle(pdfFile, dentalLaboratory, purchaseOrder, pdfWidth, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)

  if (dentalLaboratory.city) {
    pdfCurrentPositionY += pdfRowHeight
    pdfFile.text(
      'Место составления ' + dentalLaboratory.city, pdfWidth - pdfMargin, pdfCurrentPositionY, 'right'
    )
  }

  pdfCurrentPositionY += (pdfRowHeight * 2)

  let rightDataPosition = pdfWidth / 5
  pdfFile.setFont(pdfFontBold)
  pdfFile.text('Дата:', pdfMargin, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)

  let stringDate = moment(parseInt(purchaseOrder.act_creation_date)).format('L')
  pdfFile.text(stringDate, rightDataPosition, pdfCurrentPositionY)
  pdfFile.line(
    rightDataPosition,
    pdfCurrentPositionY + 1,
    rightDataPosition + getTextWidth(pdfFile, stringDate),
    pdfCurrentPositionY + 1,
  )

  if (!actContractNumberHidden()) {
    pdfFile.setFont(pdfFontBold)
    let actText = 'Договор №:  '
    pdfFile.text(actText, pdfWidth / 2, pdfCurrentPositionY, 'center')
    pdfFile.setFont(pdfFontNormal)
    const contractNumber = mixins.methods.getActContractNumber(purchaseOrder, patient)
    const contractNumberWidth = getTextWidth(pdfFile, contractNumber)
    const actTextWidth = getTextWidth(pdfFile, actText)
    pdfFile.text(contractNumber, (pdfWidth / 2) + (actTextWidth / 2) + 1, pdfCurrentPositionY)
    pdfFile.line(
      (pdfWidth / 2) + (actTextWidth / 2),
      pdfCurrentPositionY + 1,
      (pdfWidth / 2) + ((actTextWidth / 2) + contractNumberWidth + 2),
      pdfCurrentPositionY + 1
    )
  }

  pdfFile.setFont(pdfFontBold)
  pdfCurrentPositionY += pdfRowHeight

  pdfFile.text('Заказчик:', pdfMargin, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(patientName, rightDataPosition, pdfCurrentPositionY)
  pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)

  pdfFile.setFont(pdfFontBold)
  pdfCurrentPositionY += pdfRowHeight

  pdfFile.text('Адрес:', pdfMargin, pdfCurrentPositionY)

  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(patient.address ? patient.address : '', rightDataPosition, pdfCurrentPositionY)
  pdfFile.line(
    rightDataPosition,
    pdfCurrentPositionY + 1,
    pdfWidth - pdfMargin,
    pdfCurrentPositionY + 1
  )
  pdfCurrentPositionY += pdfRowHeight

  if (purchaseOrder.doctor) {
    pdfFile.setFont(pdfFontBold)
    pdfFile.text('Ф.И.О врача:', pdfMargin, pdfCurrentPositionY)
    pdfFile.setFont(pdfFontNormal)
    pdfFile.text(purchaseOrder.doctor.full_name + '', rightDataPosition, pdfCurrentPositionY)
    pdfFile.line(rightDataPosition, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
    pdfCurrentPositionY += pdfRowHeight
  }

  pdfCurrentPositionY += pdfRowHeight

  // Table services title
  const title = getServicesTableTitleForActOfServicesRendered(dentalLaboratory.name)
  pdfCurrentPositionY = textToRows(
    title, null, pdfFile, pdfMargin, pdfCurrentPositionY, pdfFontNormal, true
  )
  pdfFile.setLineHeightFactor(1)

  let rows = [
    [
      {content: 'Номер', styles: {halign: 'center'}},
      {content: 'Наименование', styles: {halign: 'center'}},
      {content: 'Кол', styles: {halign: 'center'}},
      {content: 'Цена', styles: {halign: 'center'}},
      {content: 'Сумма', styles: {halign: 'center'}}
    ]
  ]

  let totalMaterialsPrice = 0
  let totalMaterialsNds10Price = 0
  let totalMaterialsNds20Price = 0
  let totalMaterialsPriceWithNds = 0
  const materials = {}
  purchaseOrder.services_history.forEach(s => {
    s.materials_history.map(m => {
      const totalPrice = m.price * s.count * m.part_rate
      let ndsPrice
      if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
        ndsPrice = (m.price_per_unit - m.price_per_unit_without_nds) * s.count * m.part_rate
      } else {
        ndsPrice = m.nds ? (totalPrice / 100) * m.nds : null
      }
      const totalPriceWithNds = ndsPrice ? totalPrice + ndsPrice : totalPrice

      if (materials[m.id]) {
        materials[m.id].count += s.count * m.part_rate
      } else {
        materials[m.id] = {
          name: m.name,
          sku: m.sku,
          count: s.count * m.part_rate,
          price: m.price,
          price_per_unit: m.price_per_unit,
          price_per_unit_without_nds: m.price_per_unit_without_nds,
          nds: m.nds,
          unitType: m.unit_type,
        }
      }

      if (m.nds === 10) {
        totalMaterialsNds10Price += ndsPrice
      }
      if (m.nds === 20) {
        totalMaterialsNds20Price += ndsPrice
      }
      totalMaterialsPrice += totalPrice
      totalMaterialsPriceWithNds += totalPriceWithNds
    })
    totalMaterialsNds10Price = parseFloat(
      mixins.methods.formatPrice(totalMaterialsNds10Price, false)
    )
    totalMaterialsNds20Price = parseFloat(
      mixins.methods.formatPrice(totalMaterialsNds20Price, false)
    )
    totalMaterialsPriceWithNds = parseFloat(
      mixins.methods.formatPrice(totalMaterialsPriceWithNds, false)
    )
    rows.push([
      {content: s.number, styles: {halign: 'center'}},
      {content: s.name},
      {content: s.count, styles: {halign: 'center'}},
      {content: mixins.methods.formatPrice(s.total_price, true), styles: {halign: 'center'}},
      {
        content: mixins.methods.formatPrice(s.total_price * parseFloat(s.count), true),
        styles: {halign: 'center'}
      }
    ])
  })
  let totalPrice = purchaseOrder.services_history.reduce(
    (total, s) => total + parseFloat(mixins.methods.formatPrice(s.total_price * s.count, false)), 0
  )
  totalPrice = parseFloat(mixins.methods.formatPrice(totalPrice, false))
  const discountAmount = purchaseOrder.services_history.length
    ? getPaymentDiscountAmountByTreatmentDiaryServices(
      purchaseOrder.services_history, purchaseOrder.calc_discount_percent
    )
    : (purchaseOrder.calc_discount_percent / 100) * totalPrice
  let salePrice = purchaseOrder.calc_discount_percent ? (totalPrice - discountAmount) : totalPrice
  salePrice = salePrice < 0 ? 0 : salePrice
  rows.push([
    {content: 'Итого', styles: {halign: 'left'}, colSpan: 4},
    {content: mixins.methods.formatPrice(totalPrice, true), styles: { halign: 'center' }}
  ])
  pdfFile.autoTable({
    theme: 'grid',
    startY: pdfCurrentPositionY,
    body: rows,
    bodyStyles: {
      cellPadding: 1,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0]
    },
    styles: {
      font: pdfFontNormal,
      fontSize: pdfFontSize,
    },
    didParseCell: hook => {
      if (hook.row.index === 0) {
        hook.cell.styles.font = pdfFontBold
      }
    },
    didDrawPage: hook => {
      pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
    }
  })

  if (Object.keys(materials).length) {
    pdfFile.text('Использованные материалы:', pdfMargin, pdfCurrentPositionY)
    rows = [
      [
        {content: 'Артикул', styles: {halign: 'center', valign: 'middle'}},
        {content: 'Наименование', styles: {halign: 'center', valign: 'middle'}},
        {content: 'Стоимость без НДС', styles: {halign: 'center', valign: 'middle'}},
        {content: 'Количество', styles: {halign: 'center', valign: 'middle'}},
        {content: 'Ед. измерения', styles: {halign: 'center', valign: 'middle'}},
        {content: 'Итого без НДС', styles: {halign: 'center', valign: 'middle'}},
        {content: 'Ставка НДС', styles: {halign: 'center', valign: 'middle'}},
        {content: 'Сумма НДС', styles: {halign: 'center', valign: 'middle'}},
        {content: 'Итого с НДС', styles: {halign: 'center', valign: 'middle'}}
      ]
    ]

    Object.keys(materials).map(key => {
      const material = materials[key]
      const nds = material.nds ? material.nds : null
      const totalPrice = material.price * material.count
      let ndsPrice
      if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
        ndsPrice = (material.price_per_unit - material.price_per_unit_without_nds) * material.count
      } else {
        ndsPrice = nds ? (totalPrice / 100) * nds : null
      }
      const totalPriceWithNds = ndsPrice ? totalPrice + ndsPrice : totalPrice
      let row = [
        {content: material.sku, styles: {halign: 'center', valign: 'middle'}},
        {content: material.name, styles: {halign: 'center', valign: 'middle'}},
        {
          content: mixins.methods.formatPrice(material.price, true),
          styles: {halign: 'center', valign: 'middle'}
        },
        {content: material.count, styles: {halign: 'center', valign: 'middle'}},
        {content: material.unitType ? material.unitType : '', styles: {halign: 'center', valign: 'middle'}},
        {
          content: mixins.methods.formatPrice(totalPrice, true),
          styles: {halign: 'center', valign: 'middle'}
        },
        {content: nds ? `${nds}%` : '0%', styles: {halign: 'center', valign: 'middle'}},
        {content: mixins.methods.formatPrice(ndsPrice, true), styles: {halign: 'center', valign: 'middle'}},
        {
          content: mixins.methods.formatPrice(totalPriceWithNds, true),
          styles: {halign: 'center', valign: 'middle'}
        },
      ]
      rows.push(row)
    })

    pdfFile.autoTable({
      theme: 'grid',
      startY: pdfCurrentPositionY + (pdfRowHeight / 2),
      body: rows,
      bodyStyles: {
        cellPadding: 1,
        lineColor: [0, 0, 0],
        textColor: [0, 0, 0]
      },
      styles: {
        font: pdfFontNormal,
        fontSize: pdfFontSize,
      },
      didParseCell: hook => {
        if (hook.row.index === 0) {
          hook.cell.styles.font = pdfFontBold
        }
      },
      didDrawPage: hook => {
        pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
      }
    })
  }

  if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
    pdfFile.addPage()
    pdfCurrentPositionY = pdfRowHeight * 2
  }

  if (Object.keys(materials).length) {
    pdfFile.text(
      `Итого сумма без НДС: ${mixins.methods.formatPrice(totalMaterialsPrice, true)}`,
      pdfMargin,
      pdfCurrentPositionY
    )
    if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
      pdfFile.addPage()
      pdfCurrentPositionY = pdfRowHeight * 2
    } else {
      pdfCurrentPositionY += pdfRowHeight
    }
    pdfFile.text(
      `в т.ч. сумма НДС 10%: ${mixins.methods.formatPrice(totalMaterialsNds10Price, true)}`,
      pdfMargin,
      pdfCurrentPositionY
    )
    if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
      pdfFile.addPage()
      pdfCurrentPositionY = pdfRowHeight * 2
    } else {
      pdfCurrentPositionY += pdfRowHeight
    }
    pdfFile.text(
      `в т.ч. сумма НДС 20%: ${mixins.methods.formatPrice(totalMaterialsNds20Price, true)}`,
      pdfMargin,
      pdfCurrentPositionY
    )
    if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
      pdfFile.addPage()
      pdfCurrentPositionY = pdfRowHeight * 2
    } else {
      pdfCurrentPositionY += pdfRowHeight
    }
    pdfFile.text(
      `Итого сумма с учетом НДС: ${mixins.methods.formatPrice(totalMaterialsPriceWithNds, true)}`,
      pdfMargin,
      pdfCurrentPositionY
    )
    if (pdfHeight - (pdfRowHeight * 2) < pdfCurrentPositionY) {
      pdfFile.addPage()
      pdfCurrentPositionY = pdfRowHeight * 2
    } else {
      pdfCurrentPositionY += pdfRowHeight
    }
  }

  pdfFile.text(
    'Вышеперечисленные услуги выполнены полностью. Заказчик по объему, качеству и срокам оказания услуг',
    pdfMargin,
    pdfCurrentPositionY
  )
  pdfCurrentPositionY += pdfRowHeight
  pdfFile.text(
    'претензий не имеет. Настоящий акт является основанием к оплате оказанных услуг.',
    pdfMargin,
    pdfCurrentPositionY
  )
  pdfCurrentPositionY += (pdfRowHeight * 2)

  pdfFile.setFont(pdfFontBold)
  if (pdfHeight - 10 < pdfCurrentPositionY) {
    pdfFile.addPage()
    pdfCurrentPositionY = 10
  }
  pdfFile.text('ИТОГО', pdfMargin, pdfCurrentPositionY)
  pdfCurrentPositionY += pdfRowHeight

  let totalText = 'Начислено:   '
  pdfFile.text(totalText, pdfMargin, pdfCurrentPositionY)

  pdfFile.setFont(pdfFontNormal)

  pdfFile.text(
    mixins.methods.formatPrice(totalPrice, true),
    pdfMargin + getTextWidth(pdfFile, totalText),
    pdfCurrentPositionY
  )

  pdfFile.setFont(pdfFontBold)
  let discountText = 'Скидка %:   '
  pdfFile.text(discountText, (pdfWidth / 4) + 4, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(
    purchaseOrder.calc_discount_percent ? purchaseOrder.calc_discount_percent.toString() : '0',
    (pdfWidth / 4) + 4 + getTextWidth(pdfFile, discountText),
    pdfCurrentPositionY
  )

  pdfFile.setFont(pdfFontBold)
  let payText = 'К оплате:   '
  pdfFile.text(payText, pdfWidth / 2, pdfCurrentPositionY)
  pdfFile.setFont(pdfFontNormal)
  pdfFile.text(
    (salePrice > 0 && totalPrice) || purchaseOrder.calc_discount_percent === 100
      ? mixins.methods.formatPrice(salePrice, true)
      : mixins.methods.formatPrice(totalPrice, true),
    (pdfWidth / 2) + getTextWidth(pdfFile, payText),
    pdfCurrentPositionY
  )

  pdfCurrentPositionY += (pdfRowHeight * 2)
  pdfFile.setFont(pdfFontBold)
  pdfFile.setDrawColor(0,0,0,1)
  let cashierText = mixins.methods.getSignerLabelForAct()
  pdfFile.text(cashierText, pdfMargin, pdfCurrentPositionY)
  const offset = pdfMargin + pdfFile.getTextWidth(cashierText)
  if (actFillSignerDoctorName() && purchaseOrder.doctor) {
    pdfFile.setFont(pdfFontNormal)
    pdfFile.text(purchaseOrder.doctor.full_name_initials, offset, pdfCurrentPositionY)
    pdfFile.setFont(pdfFontBold)
  }
  pdfFile.line(offset, pdfCurrentPositionY + 1, offset + 40, pdfCurrentPositionY + 1)

  let patientText = 'Заказчик: '
  pdfFile.text(patientText, pdfWidth / 2, pdfCurrentPositionY)

  pdfFile.line(
    (pdfWidth / 2) + getTextWidth(pdfFile, patientText),
    pdfCurrentPositionY + 1,
    pdfWidth - pdfMargin,
    pdfCurrentPositionY + 1,
  )

  pdfFile.save(
    mixins.methods.getActTitle(purchaseOrder) + ' (с материалами) - ' + patient.full_name + '.pdf'
  )
}

export const createStatementOfWorkPerformedForMedicalServicesRenderedForPurchaseOrder = async purchaseOrderId => {
  let purchaseOrder = null
  await getPurchaseOrderInfo(purchaseOrderId).then(({ data }) => purchaseOrder = data)
  if (!purchaseOrder) {
    showFailedOperationToast(
      'Ошибка формирования акта выполненных работ по оказанным медицинским услугам.' +
      ' Пожалуйста, обратитесь в службу технической поддержки'
    )
    return
  }
  const priceCalcSystem = mixins.methods.getMaterialPriceCalcSystem()
  const dentalLaboratory = purchaseOrder.dental_laboratory
  const patient = purchaseOrder.patient
  const pdfFont = 'Times-Roman'
  const pdfFontBold = 'bold'
  const pdfFontNormal = 'normal'
  const pdfLeftMargin = 16
  const pdfRightMargin = pdfLeftMargin / 2
  const pdfFontSize = 12
  const pdfRowHeight = 7
  const pdfWidth = 210

  let pdfCurrentPositionY = 0
  let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})

  // header
  pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, dentalLaboratory, pdfFont, pdfLeftMargin)
  pdfCurrentPositionY = mixins.methods.getPositionAfterHeader(dentalLaboratory, 42, false)

  pdfFile.setFont(pdfFont, pdfFontNormal)
  pdfFile.setFontSize(pdfFontSize)
  pdfFile.text(
    'Акт выполненных работ по оказанным медицинским услугам',
    pdfWidth / 2,
    pdfCurrentPositionY,
    'center'
  )
  pdfCurrentPositionY += pdfRowHeight * 1.5
  pdfFile.text(dentalLaboratory.city ? dentalLaboratory.city : '', pdfLeftMargin, pdfCurrentPositionY)
  const date = moment(parseInt(purchaseOrder.act_creation_date)).format('ll')
  pdfFile.text(date, pdfWidth - pdfRightMargin - getTextWidth(pdfFile, date), pdfCurrentPositionY)

  pdfCurrentPositionY += pdfRowHeight * 1.5
  const patientInfo = [patient.full_name]
  if (patient.address) {
    patientInfo.push(patient.address)
  }

  if (
    patient.document_series
    && patient.document_number
    && patient.document_issued_by
    && patient.document_type === 'passport'
  ) {
    const passportInfo = [`${patient.document_series}${patient.document_number}`, patient.document_issued_by]
    patientInfo.push(`№ ${passportInfo.join(', ')}`)
  }
  let text = `${dentalLaboratory.name}, в соответствии с договором возмездного оказания медицинских услуг от `
    + `${moment(parseInt(purchaseOrder.act_creation_date)).format('L')} г., `
    + `были оказаны медицинские услуги пациенту ${patientInfo.join(', ')}, наименование медицинских услуг:`
  const splittedText = pdfFile.splitTextToSize(text, pdfWidth - (pdfLeftMargin + pdfRightMargin))
  const splittedTextItemsCount = splittedText.length
  pdfFile.text(splittedText, pdfLeftMargin, pdfCurrentPositionY)
  pdfCurrentPositionY += (pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor) * splittedTextItemsCount

  text = purchaseOrder.services_history.map(service => service.name).join(', ')
  text = mixins.methods.capitalizeFirstLetter(text)
  if (getTextWidth(pdfFile, text) > pdfWidth - (pdfLeftMargin + pdfRightMargin)) {
    const splittedText = pdfFile.splitTextToSize(text, pdfWidth - (pdfLeftMargin + pdfRightMargin))
    const splittedTextItemsCount = splittedText.length
    pdfFile.text(splittedText, pdfLeftMargin, pdfCurrentPositionY)
    pdfCurrentPositionY += (pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor) * (splittedTextItemsCount - 1)
  } else {
    pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)
  }
  pdfCurrentPositionY += pdfRowHeight * 1.5

  pdfFile.setFont(pdfFont, pdfFontBold)
  let totalPrice = purchaseOrder.services_history.reduce(
    (totalPrice, service) =>
      totalPrice + parseFloat(mixins.methods.formatPrice(service.total_price * service.count, false)),
    0
  )
  totalPrice = parseFloat(mixins.methods.formatPrice(totalPrice, false))
  const discountAmount = purchaseOrder.services_history.length
    ? getPaymentDiscountAmountByTreatmentDiaryServices(
      purchaseOrder.services_history, purchaseOrder.calc_discount_percent
    )
    : (purchaseOrder.calc_discount_percent / 100) * totalPrice
  let salePrice = purchaseOrder.calc_discount_percent ? (totalPrice - discountAmount) : totalPrice
  salePrice = salePrice < 0 ? 0 : salePrice
  text = `Выполнено работ на сумму с НДС: ${mixins.methods.formatPrice(salePrice, false)}`
  pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)
  let endTextPosition = pdfLeftMargin + getTextWidth(pdfFile, text)
  text = `(${mixins.methods.amountToHumanReadableAmount(salePrice)})`
  pdfFile.setFont(pdfFont, pdfFontNormal)
  let startTextPosition = endTextPosition + 1
  pdfFile.text(text, startTextPosition, pdfCurrentPositionY)
  pdfCurrentPositionY += 1
  pdfFile.line(pdfLeftMargin, pdfCurrentPositionY, pdfWidth - pdfRightMargin, pdfCurrentPositionY)

  pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
  text = 'Услуги без НДС (согласно ст.118 подп. 1.2  п.1 НК РБ)'
  pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)

  let totalServicesNds10Price = 0
  let totalServicesNds20Price = 0
  let totalMaterialsNds10Price = 0
  let totalMaterialsNds20Price = 0
  purchaseOrder.services_history.forEach(s => {
    const totalPrice = s.service_price * s.count
    const ndsPrice = s.nds ? (totalPrice / 100) * s.nds : null
    if (s.nds === 10) {
      totalServicesNds10Price += ndsPrice
    }
    if (s.nds === 20) {
      totalServicesNds20Price += ndsPrice
    }
    s.materials_history.map(m => {
      const totalPrice = m.price * s.count * m.part_rate
      const ndsPrice = m.nds ? (totalPrice / 100) * m.nds : null
      if (m.nds === 10) {
        if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
          totalMaterialsNds10Price += (m.price_per_unit - m.price_per_unit_without_nds) * s.count * m.part_rate
        } else {
          totalMaterialsNds10Price += ndsPrice
        }
      }
      if (m.nds === 20) {
        if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
          totalMaterialsNds20Price += (m.price_per_unit - m.price_per_unit_without_nds) * s.count * m.part_rate
        } else {
          totalMaterialsNds20Price += ndsPrice
        }
      }
    })
    totalMaterialsNds10Price = parseFloat(mixins.methods.formatPrice(totalMaterialsNds10Price, false))
    totalMaterialsNds20Price = parseFloat(mixins.methods.formatPrice(totalMaterialsNds20Price, false))
  })

  if (totalMaterialsNds10Price || totalMaterialsNds20Price || totalServicesNds10Price || totalServicesNds20Price) {
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    text = 'В том числе НДС:'
    pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)
  }

  if (totalMaterialsNds10Price || totalMaterialsNds20Price) {
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    text = 'на материалы'
    pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)
  }

  if (totalMaterialsNds10Price) {
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    totalMaterialsNds10Price = totalMaterialsNds10Price.toFixed(2)
    text = `НДС 10%: ${totalMaterialsNds10Price} (${mixins.methods.amountToHumanReadableAmount(totalMaterialsNds10Price)})`
    pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)
    pdfCurrentPositionY += 1
    pdfFile.line(pdfLeftMargin, pdfCurrentPositionY, pdfLeftMargin + getTextWidth(pdfFile, text), pdfCurrentPositionY)
  }

  if (totalMaterialsNds20Price) {
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    totalMaterialsNds20Price = totalMaterialsNds20Price.toFixed(2)
    text = `НДС 20%: ${totalMaterialsNds20Price} (${mixins.methods.amountToHumanReadableAmount(totalMaterialsNds20Price)})`
    pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)
    pdfCurrentPositionY += 1
    pdfFile.line(pdfLeftMargin, pdfCurrentPositionY, pdfLeftMargin + getTextWidth(pdfFile, text), pdfCurrentPositionY)
  }

  if (totalServicesNds10Price || totalServicesNds20Price) {
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    text = 'на тариф'
    pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)
  }

  if (totalServicesNds10Price) {
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    totalServicesNds10Price = totalServicesNds10Price.toFixed(2)
    text = `НДС 10%: ${totalServicesNds10Price} (${mixins.methods.amountToHumanReadableAmount(totalServicesNds10Price)})`
    pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)
    pdfCurrentPositionY += 1
    pdfFile.line(pdfLeftMargin, pdfCurrentPositionY, pdfLeftMargin + getTextWidth(pdfFile, text), pdfCurrentPositionY)
  }

  if (totalServicesNds20Price) {
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    totalServicesNds20Price = totalServicesNds20Price.toFixed(2)
    text = `НДС 20%: ${totalServicesNds20Price} (${mixins.methods.amountToHumanReadableAmount(totalServicesNds20Price)})`
    pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)
    pdfCurrentPositionY += 1
    pdfFile.line(pdfLeftMargin, pdfCurrentPositionY, pdfLeftMargin + getTextWidth(pdfFile, text), pdfCurrentPositionY)
  }

  pdfCurrentPositionY += pdfRowHeight * 1.5
  const doctor = purchaseOrder.doctor ? purchaseOrder.doctor.full_name_initials : ''
  text = `Врач оказавший услуги: ${doctor}`
  pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)
  pdfCurrentPositionY += 1
  const offset = purchaseOrder.doctor
    ? pdfLeftMargin + getTextWidth(pdfFile, text)
    : pdfLeftMargin + (getTextWidth(pdfFile, text) * 2)
  pdfFile.line(pdfLeftMargin, pdfCurrentPositionY, offset, pdfCurrentPositionY)

  pdfCurrentPositionY += pdfRowHeight * 1.5
  text = 'Качество оказанных услуг соответствует предъявленным требованиям, стороны претензий друг к'
  pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)

  pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
  text = 'другу не имеют.'
  pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)

  const pageCenter = pdfWidth / 2
  pdfCurrentPositionY += pdfRowHeight * 1.5
  text = 'Исполнитель'
  pdfFile.text(text, pdfLeftMargin, pdfCurrentPositionY)

  pdfCurrentPositionY += 1
  pdfFile.line(
    pdfLeftMargin + getTextWidth(pdfFile, text) + 10, pdfCurrentPositionY, pageCenter - 10, pdfCurrentPositionY
  )
  pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
  text = '(подпись)'
  pdfFile.text(text, pdfLeftMargin + getTextWidth(pdfFile, text) + 30, pdfCurrentPositionY)

  pdfCurrentPositionY -= pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
  pdfCurrentPositionY -= 1
  text = 'Заказчик'
  pdfFile.text(text, pageCenter, pdfCurrentPositionY)

  pdfCurrentPositionY += 1
  pdfFile.line(
    pageCenter + getTextWidth(pdfFile, text) + 10, pdfCurrentPositionY, pdfWidth - pdfLeftMargin - 10, pdfCurrentPositionY
  )
  pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
  text = '(подпись)'
  pdfFile.text(text, pageCenter + getTextWidth(pdfFile, text) + 26, pdfCurrentPositionY)

  pdfFile.save(
    'Акт выполненных работ по оказанным медицинским услугам - ' + patient.full_name + '.pdf'
  )
}

export const createPurchaseOrderDocumentForPurchaseOrder = async purchaseOrderId => {
  let purchaseOrder = null
  await getPurchaseOrderInfo(purchaseOrderId).then(({ data }) => purchaseOrder = data)
  if (!purchaseOrder) {
    showFailedOperationToast(
      'Ошибка формирования заказ-наряда. Пожалуйста, обратитесь в службу технической поддержки'
    )
    return
  }
  const priceCalcSystem = mixins.methods.getMaterialPriceCalcSystem()
  const dentalLaboratory = purchaseOrder.dental_laboratory
  const patient = purchaseOrder.patient
  let pdfFont = 'Times-Roman'
  const pdfFontBold = 'bold'
  const pdfFontNormal = 'normal'
  const pdfMargin = 8
  const pdfFontSize = 11
  const pdfRowHeight = 7
  const date = moment(parseInt(purchaseOrder.act_creation_date)).format('LL')
  let pdfFile = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})
  const pdfHeight = pdfFile.internal.pageSize.height
  const pdfWidth = pdfFile.internal.pageSize.width

  // header
  pdfFile = mixins.methods.getPdfDocumentHeader(pdfFile, dentalLaboratory, pdfFont, pdfMargin)
  let pdfCurrentPositionY = mixins.methods.getPositionAfterHeader(dentalLaboratory, 42, false)

  pdfFont = 'Microsoft-Sans-Serif'
  pdfFile.setFont(pdfFont, pdfFontBold)
  pdfFile.setFontSize(13.5)
  pdfFile.text('Заказ-наряд', pdfWidth / 2, pdfCurrentPositionY, 'center')

  pdfFont = 'Times-Roman'
  pdfFile.setFont(pdfFont, pdfFontBold)
  pdfFile.setFontSize(pdfFontSize)

  pdfCurrentPositionY += pdfRowHeight * 1.5
  let text = 'Дата:'
  pdfFile.text(text, pdfMargin, pdfCurrentPositionY)
  pdfFile.setFont(pdfFont, pdfFontNormal)
  pdfFile.text(date, pdfMargin + getTextWidth(pdfFile, text) + 2, pdfCurrentPositionY)

  pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
  pdfFile.setFont(pdfFont, pdfFontBold)
  text = 'Пациент:'
  pdfFile.text(text, pdfMargin, pdfCurrentPositionY)
  pdfFile.setFont(pdfFont, pdfFontNormal)
  let patientInfo = [patient.full_name]
  if (patient.address) {
    patientInfo.push(patient.address)
  }

  if (
    patient.document_series
    && patient.document_number
    && patient.document_issued_by
    && patient.document_type === 'passport'
  ) {
    const passportInfo = [`${patient.document_series}${patient.document_number}`, patient.document_issued_by]
    patientInfo.push(`№ ${passportInfo.join(', ')}`)
  }
  patientInfo = patientInfo.join(', ')
  if (getTextWidth(pdfFile, patientInfo) > pdfWidth - getTextWidth(pdfFile, text) - pdfMargin * 2) {
    const splittedText = pdfFile.splitTextToSize(
      patientInfo, pdfWidth - getTextWidth(pdfFile, text) - pdfMargin * 2
    )
    const splittedTextItemsCount = splittedText.length
    pdfFile.text(splittedText[0], pdfMargin + getTextWidth(pdfFile, text) + 2, pdfCurrentPositionY)
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    pdfFile.text(splittedText.slice(1), pdfMargin, pdfCurrentPositionY)
    pdfCurrentPositionY += (pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor) * (splittedTextItemsCount - 1)
  } else {
    pdfFile.text(patientInfo, pdfMargin + getTextWidth(pdfFile, text) + 2, pdfCurrentPositionY)
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
  }
  let rows = [
    [
      {content: 'Наименование услуги', styles: {cellWidth: 50, halign: 'center', valign: 'middle'}, rowSpan: 2},
      {content: 'Количество', styles: {cellWidth: 14, halign: 'center', valign: 'middle'}, rowSpan: 2},
      {content: 'Тариф услуги', styles: {halign: 'center', valign: 'middle'}, colSpan: 2},
      {content: 'Стоимость материалов', styles: {halign: 'center', valign: 'middle'}, colSpan: 5},
      {content: 'Всего', styles: {halign: 'center', valign: 'middle'}, rowSpan: 2},
    ],
    [
      {content: 'Без НДС', styles: {halign: 'center', valign: 'middle'}},
      {content: 'НДС', styles: {halign: 'center', valign: 'middle'}},
      {content: 'Освобожденных от НДС', styles: {halign: 'center', valign: 'middle'}},
      {content: 'Облагаемых НДС без учета НДС 10%', styles: {halign: 'center', valign: 'middle'}},
      {content: 'Облагаемых НДС без учета НДС 20%', styles: {halign: 'center', valign: 'middle'}},
      {content: 'Сумма НДС по ставке 10%', styles: {halign: 'center', valign: 'middle'}},
      {content: 'Сумма НДС по ставке 20%', styles: {halign: 'center', valign: 'middle'}},
    ],
  ]

  let totalServicesCount = 0
  let totalServicesPriceWithoutNds = 0
  let totalNdsPrice = 0
  let totalMaterialsPriceWithoutNds = 0
  let commonTotalMaterialsNds10Price = 0
  let commonTotalMaterialsNds20Price = 0
  let commonTotalMaterialsWithNds10Price = 0
  let commonTotalMaterialsWithNds20Price = 0
  let commonTotalPrice = 0
  let totalServicesNds10Price = 0
  let totalServicesNds20Price = 0
  purchaseOrder.services_history.forEach(s => {
    let ndsPrice = 0
    const totalPrice =
      parseFloat(mixins.methods.formatPrice(s.total_price * s.count, false))
    if (s.nds) {
      const nds = s.nds
      ndsPrice = nds ? ((s.service_price * s.count / 100) * nds) : 0
      if (nds === 10) {
        totalServicesNds10Price += ndsPrice
      }
      if (nds === 20) {
        totalServicesNds20Price += ndsPrice
      }
    }

    let materialPriceWithoutNds = 0
    let totalMaterialsNds10Price = 0
    let totalMaterialsNds20Price = 0
    let totalMaterialsWithNds10Price = 0
    let totalMaterialsWithNds20Price = 0
    s.materials_history.map(m => {
      const nds = m.nds
      const pricePerUnit = m.price
      let materialPrice = pricePerUnit * s.count * m.part_rate
      let materialNdsPrice = nds ? (nds / 100) * materialPrice : 0
      materialPriceWithoutNds += !nds ? pricePerUnit * s.count * m.part_rate : 0
      if (nds === 10) {
        if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
          totalMaterialsNds10Price += (m.price_per_unit - m.price_per_unit_without_nds) * s.count * m.part_rate
          totalMaterialsWithNds10Price += materialPrice
        } else {
          totalMaterialsNds10Price += materialNdsPrice
          totalMaterialsWithNds10Price += materialPrice
        }
      }
      if (nds === 20) {
        if (appConstants.material.materialPriceCalcSystem.USN === priceCalcSystem) {
          totalMaterialsNds20Price += (m.price_per_unit - m.price_per_unit_without_nds) * s.count * m.part_rate
          totalMaterialsWithNds20Price += materialPrice
        } else {
          totalMaterialsNds20Price += materialNdsPrice
          totalMaterialsWithNds20Price += materialPrice
        }
      }
    })
    materialPriceWithoutNds = materialPriceWithoutNds ? materialPriceWithoutNds : 0

    let servicePriceWithoutNds = s.service_price * s.count
    servicePriceWithoutNds = servicePriceWithoutNds ? servicePriceWithoutNds : 0
    rows.push([
      {content: `${s.number} ${s.name}`, styles: {halign: 'left', valign: 'middle'}},
      {content: s.count, styles: {halign: 'center', valign: 'middle'}},
      {
        content: mixins.methods.formatPrice(servicePriceWithoutNds, false),
        styles: {halign: 'center', valign: 'middle'}
      },
      {
        content: mixins.methods.formatPrice(ndsPrice, false),
        styles: {halign: 'center', valign: 'middle'}
      },
      {
        content: mixins.methods.formatPrice(materialPriceWithoutNds, false),
        styles: {halign: 'center', valign: 'middle'}
      },
      {
        content: mixins.methods.formatPrice(totalMaterialsWithNds10Price, false),
        styles: {halign: 'center', valign: 'middle'}
      },
      {
        content: mixins.methods.formatPrice(totalMaterialsWithNds20Price, false),
        styles: {halign: 'center', valign: 'middle'}
      },
      {
        content: mixins.methods.formatPrice(totalMaterialsNds10Price, false),
        styles: {halign: 'center', valign: 'middle'}
      },
      {
        content: mixins.methods.formatPrice(totalMaterialsNds20Price, false),
        styles: {halign: 'center', valign: 'middle'}
      },
      {
        content: mixins.methods.formatPrice(totalPrice, false),
        styles: {halign: 'center', valign: 'middle'}
      }
    ])

    totalServicesCount += s.count
    totalServicesPriceWithoutNds += parseFloat(servicePriceWithoutNds)
    totalNdsPrice += ndsPrice
    totalMaterialsPriceWithoutNds += parseFloat(materialPriceWithoutNds)
    commonTotalMaterialsNds10Price += parseFloat(mixins.methods.formatPrice(totalMaterialsNds10Price, false))
    commonTotalMaterialsNds20Price += parseFloat(mixins.methods.formatPrice(totalMaterialsNds20Price, false))
    commonTotalMaterialsWithNds10Price += parseFloat(mixins.methods.formatPrice(totalMaterialsWithNds10Price, false))
    commonTotalMaterialsWithNds20Price += parseFloat(mixins.methods.formatPrice(totalMaterialsWithNds20Price, false))
    commonTotalPrice += parseFloat(totalPrice)
    commonTotalMaterialsNds10Price = parseFloat(
      mixins.methods.formatPrice(commonTotalMaterialsNds10Price, false)
    )
    commonTotalMaterialsNds20Price = parseFloat(
      mixins.methods.formatPrice(commonTotalMaterialsNds20Price, false)
    )
  })

  totalServicesPriceWithoutNds = totalServicesPriceWithoutNds ? totalServicesPriceWithoutNds : 0
  totalNdsPrice = totalNdsPrice ? totalNdsPrice : 0
  totalMaterialsPriceWithoutNds = totalMaterialsPriceWithoutNds ? totalMaterialsPriceWithoutNds : 0
  commonTotalPrice = commonTotalPrice ? commonTotalPrice : 0
  rows.push([
    {content: 'ИТОГО', styles: {halign: 'left', valign: 'middle'}},
    {content: totalServicesCount, styles: {halign: 'center', valign: 'middle'}},
    {
      content: mixins.methods.formatPrice(totalServicesPriceWithoutNds, false),
      styles: {halign: 'center', valign: 'middle'}
    },
    {
      content: mixins.methods.formatPrice(totalNdsPrice, false),
      styles: {halign: 'center', valign: 'middle'}
    },
    {
      content: mixins.methods.formatPrice(totalMaterialsPriceWithoutNds, false),
      styles: {halign: 'center', valign: 'middle'}
    },
    {
      content: mixins.methods.formatPrice(commonTotalMaterialsWithNds10Price, false),
      styles: {halign: 'center', valign: 'middle'}
    },
    {
      content: mixins.methods.formatPrice(commonTotalMaterialsWithNds20Price, false),
      styles: {halign: 'center', valign: 'middle'}},
    {
      content: mixins.methods.formatPrice(commonTotalMaterialsNds10Price, false),
      styles: {halign: 'center', valign: 'middle'}
    },
    {
      content: mixins.methods.formatPrice(commonTotalMaterialsNds20Price, false),
      styles: {halign: 'center', valign: 'middle'}
    },
    {
      content: mixins.methods.formatPrice(commonTotalPrice, false),
      styles: {halign: 'center', valign: 'middle'}
    }
  ])

  pdfFile.autoTable({
    theme: 'grid',
    startY: pdfCurrentPositionY,
    margin: pdfMargin,
    body: rows,
    bodyStyles: {
      cellPadding: 1,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0]
    },
    styles: {
      font: 'Microsoft-Sans-Serif',
      fontSize: 9,
    },
    didParseCell: hook => {
      hook.cell.styles.fontSize = hook.row.index > 1 && hook.column.index === 0 ? 7.5 : hook.cell.styles.fontSize
      hook.cell.styles.font = hook.row.index > 1 && hook.column.index === 0
        ? 'Microsoft-Sans-Serif' : hook.cell.styles.font
    },
    didDrawPage: hook => {
      pdfCurrentPositionY = (hook.cursor.y + pdfRowHeight)
    }
  })

  if (pdfHeight - 10 < pdfCurrentPositionY) {
    pdfFile.addPage()
    pdfCurrentPositionY = 10
  }

  pdfFile.setDrawColor(0,0,0,1)
  pdfFile.setFont(pdfFont, pdfFontBold)
  pdfFile.setFontSize(11)
  const mainText = `Выполнено работ на сумму с НДС: ${mixins.methods.formatPrice(commonTotalPrice, false)}`
  pdfFile.text(mainText, pdfMargin, pdfCurrentPositionY)
  text = `(${mixins.methods.priceToText(commonTotalPrice)})`
  pdfFile.setFont(pdfFont, pdfFontNormal)

  if (getTextWidth(pdfFile, text) > pdfWidth - getTextWidth(pdfFile, mainText) - pdfMargin * 2) {
    const splittedText = pdfFile.splitTextToSize(
      text, pdfWidth - getTextWidth(pdfFile, mainText) - pdfMargin * 2
    )
    pdfFile.text(splittedText[0], pdfMargin + getTextWidth(pdfFile, mainText) + 4, pdfCurrentPositionY)
    pdfFile.line(pdfMargin, pdfCurrentPositionY + 1, pdfWidth - pdfMargin, pdfCurrentPositionY + 1)
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    pdfFile.text(splittedText.slice(1), pdfMargin, pdfCurrentPositionY)
    pdfCurrentPositionY += 1
    pdfFile.line(pdfMargin, pdfCurrentPositionY, pdfWidth - pdfMargin, pdfCurrentPositionY)
  } else {
    pdfFile.text(text, pdfMargin + getTextWidth(pdfFile, mainText) + 4, pdfCurrentPositionY)
    pdfCurrentPositionY += 1
    pdfFile.line(pdfMargin, pdfCurrentPositionY, pdfWidth - pdfMargin, pdfCurrentPositionY)
  }

  if (
    commonTotalMaterialsNds20Price || commonTotalMaterialsNds10Price
    || totalServicesNds10Price || totalServicesNds20Price
  ) {
    if (pdfHeight - 10 < pdfCurrentPositionY) {
      pdfFile.addPage()
      pdfCurrentPositionY = 10
    }
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    text = 'В том числе НДС:'
    pdfFile.text(text, pdfMargin, pdfCurrentPositionY)
  }

  if (commonTotalMaterialsNds20Price || commonTotalMaterialsNds10Price) {
    if (pdfHeight - 10 < pdfCurrentPositionY) {
      pdfFile.addPage()
      pdfCurrentPositionY = 10
    }
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    text = 'на материалы'
    pdfFile.text(text, pdfMargin, pdfCurrentPositionY)

    if (commonTotalMaterialsNds10Price) {
      if (pdfHeight - 10 < pdfCurrentPositionY) {
        pdfFile.addPage()
        pdfCurrentPositionY = 10
      }
      pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
      commonTotalMaterialsNds10Price = commonTotalMaterialsNds10Price.toFixed(2)
      text = `НДС 10%: ${commonTotalMaterialsNds10Price} (${mixins.methods.priceToText(commonTotalMaterialsNds10Price)})`
      pdfFile.text(text, pdfMargin, pdfCurrentPositionY)
      pdfCurrentPositionY += 1
      pdfFile.line(pdfMargin, pdfCurrentPositionY, pdfMargin + getTextWidth(pdfFile, text), pdfCurrentPositionY)
    }

    if (commonTotalMaterialsNds20Price) {
      if (pdfHeight - 10 < pdfCurrentPositionY) {
        pdfFile.addPage()
        pdfCurrentPositionY = 10
      }
      pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
      commonTotalMaterialsNds20Price = commonTotalMaterialsNds20Price.toFixed(2)
      text = `НДС 20%: ${commonTotalMaterialsNds20Price} (${mixins.methods.priceToText(commonTotalMaterialsNds20Price)})`
      pdfFile.text(text, pdfMargin, pdfCurrentPositionY)
      pdfCurrentPositionY += 1
      pdfFile.line(pdfMargin, pdfCurrentPositionY, pdfMargin + getTextWidth(pdfFile, text), pdfCurrentPositionY)
    }
  }

  if (totalServicesNds10Price || totalServicesNds20Price) {
    if (pdfHeight - 10 < pdfCurrentPositionY) {
      pdfFile.addPage()
      pdfCurrentPositionY = 10
    }
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    text = 'на тариф'
    pdfFile.text(text, pdfMargin, pdfCurrentPositionY)
  }

  if (totalServicesNds10Price) {
    if (pdfHeight - 10 < pdfCurrentPositionY) {
      pdfFile.addPage()
      pdfCurrentPositionY = 10
    }
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    totalServicesNds10Price = totalServicesNds10Price.toFixed(2)
    text = `НДС 10%: ${totalServicesNds10Price} (${mixins.methods.priceToText(totalServicesNds10Price)})`
    pdfFile.text(text, pdfMargin, pdfCurrentPositionY)
    pdfCurrentPositionY += 1
    pdfFile.line(pdfMargin, pdfCurrentPositionY, pdfMargin + getTextWidth(pdfFile, text), pdfCurrentPositionY)
  }

  if (totalServicesNds20Price) {
    if (pdfHeight - 10 < pdfCurrentPositionY) {
      pdfFile.addPage()
      pdfCurrentPositionY = 10
    }
    pdfCurrentPositionY += pdfFile.getLineHeight(text) / pdfFile.internal.scaleFactor
    totalServicesNds20Price = totalServicesNds20Price.toFixed(2)
    text = `НДС 20%: ${totalServicesNds20Price} (${mixins.methods.priceToText(totalServicesNds20Price)})`
    pdfFile.text(text, pdfMargin, pdfCurrentPositionY)
    pdfCurrentPositionY += 1
    pdfFile.line(pdfMargin, pdfCurrentPositionY, pdfMargin + getTextWidth(pdfFile, text), pdfCurrentPositionY)
  }

  pdfCurrentPositionY += pdfRowHeight * 1.5

  if (pdfHeight - 10 < pdfCurrentPositionY) {
    pdfFile.addPage()
    pdfCurrentPositionY = 10
  }

  text = `${purchaseOrderExecutorLabel()}:`
  pdfFile.text(text, pdfMargin, pdfCurrentPositionY)
  if (purchaseOrderFillExecutorDoctorName() && purchaseOrder.doctor) {
    pdfFile.text(
      purchaseOrder.doctor.full_name_initials,
      pdfMargin + 1 + getTextWidth(pdfFile, text),
      pdfCurrentPositionY
    )
  }
  pdfCurrentPositionY += 1
  pdfFile.line(pdfMargin, pdfCurrentPositionY, pdfWidth - pdfMargin, pdfCurrentPositionY)

  pdfCurrentPositionY += pdfRowHeight * 1.5
  if (pdfHeight - 10 < pdfCurrentPositionY) {
    pdfFile.addPage()
    pdfCurrentPositionY = 10
  }

  if (!purchaseOrderAdministratorHidden()) {
    text = 'Администратор:'
    pdfFile.text(text, pdfMargin, pdfCurrentPositionY)
    pdfCurrentPositionY += 1
    pdfFile.line(pdfMargin, pdfCurrentPositionY, pdfWidth - pdfMargin, pdfCurrentPositionY)
  }

  pdfFile.save('Заказ-наряд - ' + patient.full_name + ` (${date}).pdf`)
}

export const createInvoiceForPurchaseOrder = async purchaseOrderId => {
  await store.dispatch(
    'dentalLaboratory/getPurchaseOrderActInvoiceExcelFile', {purchaseOrderId}
  )
    .then(({ data }) => {
      const fileName = `Счет (зуботехнические работы).xlsx`
      saveExcelFileFromResponse(data, fileName)
    })
    .catch(() => showFailedOperationToast('Не удалось сформировать счет'))
}

export const createWaybillForPurchaseOrder = async purchaseOrderId => {
  await store.dispatch(
    'dentalLaboratory/getPurchaseOrderActWaybillExcelFile', {purchaseOrderId}
  )
    .then(({ data }) => {
      const fileName = `Накладная (зуботехнические работы).xlsx`
      saveExcelFileFromResponse(data, fileName)
    })
    .catch(() => showFailedOperationToast('Не удалось сформировать накладную'))
}

export const createIntegratorInvoiceForServices = async billingStatementId => {
  let billingStatement = null
  await store.dispatch('revenue/getBillingStatement', billingStatementId)
    .then(({ data }) => billingStatement = data)
  if (!billingStatement || !billingStatement.integrator) {
    showFailedOperationToast(
      'Ошибка формирования счет-протокола. Пожалуйста, обратитесь в службу технической поддержки'
    )
    return
  }
  const createdDate = moment(parseInt(billingStatement.created_at))
  const date = createdDate.format('LL')
  let pdf = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})
  const pdfWidth = pdf.internal.pageSize.width
  const scaleFactor = pdf.internal.scaleFactor
  const pdfMarginTop = 13.5
  const pdfMarginLeft = 11
  const pdfMarginRight = 6.5
  const fontStyleBold = 'bold'
  const integrator = billingStatement.integrator
  const bankInfo = []
  let positionY = pdfMarginTop

  if (integrator.current_account) {
    bankInfo.push(`р/с № ${integrator.current_account.trim()}`)
  }
  if (integrator.bank) {
    bankInfo.push(integrator.bank.trim())
  }
  if (integrator.bic) {
    bankInfo.push(`БИК ${integrator.bic.trim()}`)
  }
  let rows = [
    [
      {content: 'Поставщик и его адрес:', styles: {cellWidth: 19, fontStyle: fontStyleBold, fontSize: 7}},
      {content: 'Частное предприятие "Люди Создают"', styles: {fontStyle: fontStyleBold, fontSize: 9, cellWidth: 80}},
      {content: 'СЧЕТ-ПРОТОКОЛ', styles: {halign: 'center', valign: 'middle', fontStyle: fontStyleBold, fontSize: 12}},
    ],
    [
      {content: ''},
      {content: '220055 г. Минск,уп.Каменногорская 47/42', styles: {fontSize: 9}},
      {
        content: `согласования свободных договорных цен на услуги ${billingStatement.account_number} от ${createdDate.format('LL')}`,
        rowSpan: 2, styles: {halign: 'center', valign: 'middle', fontStyle: fontStyleBold, fontSize: 9}
      }
    ],
    [{content: ''}, {content: '№ BY06ALFA30122757640010270000, Банк: ЗАО', styles: {fontSize: 9}}],
    [{content: ''}, {content: '"Альфа-Банк•. Отдепение •на Притыцкого" в г.', styles: {fontSize: 9}}],
    [{content: ''}, {content: 'Минске г. Минск, уп. Притыцкого, 39 БИК ALFAВY2X', styles: {fontSize: 9}}],
    [{content: ''}, {content: 'тел.+375 (44) 781-50-04', styles: {fontSize: 9}}],
    [{content: ''}, {content: 'УНП 193497888', styles: {fontSize: 9}}],
    [
      {content: 'Покупатель и его адрес:', styles: {fontStyle: fontStyleBold, fontSize: 7}},
      {content: integrator.name, colSpan: 2, styles: {fontStyle: fontStyleBold, fontSize: 9}},
    ],
    [{content: ''}, {content: integrator.address ? integrator.address : '', colSpan: 2, styles: {fontSize: 9}}],
    [{content: ''}, {content: bankInfo.length ? bankInfo.join(', ') : '', colSpan: 2, styles: {fontSize: 9}}],
    [{content: ''}, {content: integrator.unp ? `УНП ${integrator.unp}` : '', colSpan: 2, styles: {fontSize: 9}}],
    [
      {
        content: `Основание: ${integrator.software_usage_agreement
          ? `Договор Пользования Программным Обеспечением ${integrator.software_usage_agreement}` : ''}`,
        colSpan: 3,
        styles: {fontStyle: fontStyleBold, fontSize: 7}
      },
    ],
  ]
  pdf.autoTable({
    theme: 'grid',
    startY: positionY,
    margin: {top: pdfMarginTop, right: pdfMarginRight, bottom: 0, left: pdfMarginLeft},
    body: rows,
    bodyStyles: {
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0]
    },
    styles: {
      font: 'Microsoft-Sans-Serif',
      fontSize: 9,
    },
    didParseCell: hook => {
      if (!hook.row.index) {
        hook.cell.styles.lineWidth = {top: 0.1, right: 0.1, bottom: 0, left: 0.1}
      } else if (hook.row.index >= 1 && hook.row.index <= 6) {
        hook.cell.styles.lineWidth = {top: 0, right: 0.1, bottom: hook.row.index === 6 ? 0.1 : 0, left: 0.1}
      } else if (hook.row.index >= 7 && hook.row.index <= 10) {
        hook.cell.styles.lineWidth = {
          top: hook.row.index === 7 ? 0.1 : 0, right: 0.1, bottom: hook.row.index === 10 ? 0.1 : 0, left: 0.1
        }
      }
      hook.cell.styles.cellPadding = {
        top: ![0, 7, 11].includes(hook.row.index) ? 0 : 0.8,
        right: 0.8,
        bottom: ![0, 7, 11].includes(hook.row.index) ? 0 : 0.4,
        left: 0.8
      }
    },
    didDrawPage: hook => {
      positionY = hook.cursor.y
    }
  })
  const amount = billingStatement.subscription_amount ? billingStatement.subscription_amount : ''
  rows = [
    [
      {content: 'Предмет счета', styles: {halign: 'center', valign: 'middle', cellWidth: 99}},
      {content: 'Стоимость работ (услуг) без НДС, BYN', styles: {halign: 'center', valign: 'middle', cellWidth: 29}},
      {content: 'Ставка НДС, %', styles: {halign: 'center', valign: 'middle'}},
      {content: 'Сумма НДС, BYN', styles: {halign: 'center', valign: 'middle'}},
      {content: 'Сумма с НДС, BYN', styles: {halign: 'center', valign: 'middle'}},
    ],
    [
      {content: 'Предоставление доступа к ПО "ПрофманХэлс"', styles: {}},
      {content: mixins.methods.formatPrice(amount, false, true), styles: {halign: 'right'}},
      {content: 'Без НДС', styles: {halign: 'center'}},
      {content: ''},
      {
        content: mixins.methods.formatPrice(amount, false, true), styles: {halign: 'right'}
      },
    ],
    [
      {content: 'Итого:', styles: {font: 'OpenSans-Bold'}},
      {
        content: mixins.methods.formatPrice(amount, false, true),
        styles: {halign: 'right', font: 'OpenSans-Bold'}
      },
      {content: 'x', styles: {halign: 'center', font: 'OpenSans-Bold'}},
      {content: ''},
      {
        content: mixins.methods.formatPrice(amount, false, true),
        styles: {halign: 'right', font: 'OpenSans-Bold'}
      },
    ]
  ]
  pdf.autoTable({
    theme: 'grid',
    startY: positionY + 5,
    margin: {top: 0, right: pdfMarginRight, bottom: 0, left: pdfMarginLeft},
    body: rows,
    bodyStyles: {
      cellPadding: {top: 0.8, right: 0.8, bottom: 0.4, left: 0.8},
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0]
    },
    styles: {
      font: 'Microsoft-Sans-Serif',
      fontSize: 9,
    },
    didDrawPage: hook => {
      positionY = hook.cursor.y
    }
  })

  let text = 'Сумма НДС:'
  positionY += pdf.getTextDimensions(text).h + scaleFactor
  let textWidth = getTextWidth(pdf, text)
  let offset = pdfMarginLeft * 3 + textWidth
  pdf.setFont('OpenSans-Bold')
  pdf.setFontSize(10)
  pdf.text(text, pdfMarginLeft, positionY)
  pdf.text('Ноль белорусских рублей ноль копеек', offset, positionY)
  positionY += 1
  pdf.line(offset - 1, positionY, pdfWidth - pdfMarginRight, positionY)

  positionY += (pdf.getTextDimensions(text).h + scaleFactor) * 1.5
  text = 'Всего:'
  pdf.text(text, pdfMarginLeft, positionY)
  pdf.text(
    mixins.methods.capitalizeFirstLetter(
      mixins.methods.priceToText(billingStatement.subscription_amount)
    ),
    offset, positionY
  )
  positionY += 1
  pdf.line(offset - 1, positionY, pdfWidth - pdfMarginRight, positionY)

  positionY += (pdf.getTextDimensions(text).h + scaleFactor ) * 1.5
  pdf.setFont('OpenSans-Regular')
  pdf.setFontSize(10)
  pdf.text(
    `Счет действителен до: ${createdDate.add(3, 'days').format('LL')}`, offset, positionY
  )

  positionY += 10
  const scaling_y = 59 / 240
  const width = 286 * scaling_y
  pdf.addImage(stampSrc, 'png', pdfMarginLeft, positionY, width, 59)

  pdf.save(`Счет-протокол, ${date}.pdf`)
}

export const createTreatmentDiaryDocument = async (
  treatmentDiary, patient, fileName, isLastVisiting = false
) => {
  const integratorId = getIntegratorId()
  if (integratorId) {
    await store.dispatch('info/getIntegrator', integratorId)
      .then(response => {
        const integrator = response.data
        if (!treatmentDiary || !patient || !integrator) {
          showCommonToast(
            `Ошибка формирования ${isLastVisiting ? 'последнего посещения' : 'дневника лечения'}`,
            'Пожалуйста, обратитесь в службу технической поддержки',
            'danger'
          )
          return false
        }

        let pdfRowHeight = 7
        let pdfMargin = 8
        let pdf = new jsPDF({orientation: 'portrait', unit: 'mm', format: 'a4', putOnlyUsedFonts: true})
        let pdfCurrentPositionY = 10
        const pdfFontBold = 'OpenSans-Bold'
        const pdfFontNormal = 'OpenSans-Regular'
        const pdfWidth = 210
        const savedPdfMargin = pdfMargin
        const diaryPaddingX = 2
        const diaryContentRowHeight = pdfRowHeight - 2
        const treatmentMedicalHistory = treatmentDiary.treatment_medical_history
          ? treatmentDiary.treatment_medical_history : ''
        const showEmptyFields = mixins.methods.showEmptyFieldInLastVisiting()
        const treatmentDate = treatmentDiary.date
          ? moment(parseInt(treatmentDiary.date)).format('DD.MM.YYYY') : null

        // header
        if (!isLastVisiting || mixins.methods.isCredentialsVisibleInLastVisit()) {
          pdf = mixins.methods.getPdfDocumentHeader(pdf, integrator, pdfFontNormal, pdfMargin)
          pdfCurrentPositionY = mixins.methods.getPositionAfterHeader(integrator, 42, false)
        } else {
          pdf.setFontSize(8)
          pdf.setFont(pdfFontNormal)
        }

        pdf.setFont(pdfFontBold)
        pdf.text('Дневник посещения', pdfWidth / 2, pdfCurrentPositionY, 'center')
        pdfCurrentPositionY += pdfRowHeight

        // Patient info
        pdf.setFont(pdfFontBold)
        let label = 'Пациент:'
        pdf.text(label, pdfMargin, pdfCurrentPositionY)
        const left = pdfMixins.methods.getTextWidth(pdf, label) + pdfMargin + 1
        pdf.setFont(pdfFontNormal)
        pdf.text(patient.full_name, left, pdfCurrentPositionY)
        pdfCurrentPositionY += pdfRowHeight

        // Treatment diary
        const rightBlockWidth = 50
        pdf.setDrawColor(0, 0, 0, 1)
        pdf.line(pdfMargin, pdfCurrentPositionY, pdfWidth - pdfMargin, pdfCurrentPositionY)
        pdfCurrentPositionY += pdfRowHeight

        let text = treatmentDiary.type === 'DENTAL'
          ? 'Дата, жалобы, клиническая картина, результаты исследований, диагноз, план лечения, лечение'
          : 'Дата, диагноз, план лечения, лечение'
        let textMaxWidth = pdfWidth - ((pdfMargin * 2) + rightBlockWidth)
        let textLines = pdf.splitTextToSize(
          text,
          textMaxWidth
        )
        pdf.text(textLines, (pdfWidth / 2) - 25, pdfCurrentPositionY - 1.4, 'center')

        let rightBlockText = 'Фамилия, имя, отчество врача, подпись'
        let rightBlockLines = pdf.splitTextToSize(rightBlockText, rightBlockWidth - 5)
        pdf.text(rightBlockLines, pdfWidth - 33, pdfCurrentPositionY - 2.5, 'center')
        let textDimension = pdf.getTextDimensions(text, {maxWidth: textMaxWidth})
        let diaryContentLineTop = (pdfCurrentPositionY - pdfRowHeight)

        pdfCurrentPositionY += textDimension.h
        const tableHeaderBottomLinepPosition = pdfCurrentPositionY
        pdf.line(pdfMargin, pdfCurrentPositionY, pdfWidth - pdfMargin, pdfCurrentPositionY)

        // Diary content
        pdf.setFont(pdfFontNormal)
        pdfCurrentPositionY += diaryContentRowHeight
        pdf.text(treatmentDate, pdfMargin + diaryPaddingX, pdfCurrentPositionY + 1)
        pdfCurrentPositionY += diaryContentRowHeight
        pdf.line(pdfMargin, pdfCurrentPositionY, pdfWidth - (pdfMargin + rightBlockWidth), pdfCurrentPositionY)

        pdfCurrentPositionY += diaryContentRowHeight + 2
        pdfMargin += diaryPaddingX
        pdfRowHeight -= 2

        if (treatmentDiary.type === 'DENTAL') {
          if (showEmptyFields || treatmentDiary.complaint) {
            pdfCurrentPositionY = addTextWithMultiRows(
              pdf,
              pdfCurrentPositionY,
              pdfRowHeight,
              pdfMargin,
              'Жалобы:',
              treatmentDiary.complaint ? treatmentDiary.complaint : '',
              rightBlockWidth,
              false,
              true
            )
          }

          if (showEmptyFields || treatmentDiary.anamnesis) {
            pdfCurrentPositionY = addTextWithMultiRows(
              pdf,
              pdfCurrentPositionY,
              pdfRowHeight,
              pdfMargin,
              'Анамнез:',
              treatmentDiary.anamnesis ? treatmentDiary.anamnesis : '',
              rightBlockWidth,
              false,
              true
            )
          }

          if (showEmptyFields || treatmentDiary.clinical_picture) {
            pdfCurrentPositionY = addTextWithMultiRows(
              pdf,
              pdfCurrentPositionY,
              pdfRowHeight,
              pdfMargin,
              'Клиническая картина:',
              treatmentDiary.clinical_picture ? treatmentDiary.clinical_picture : '',
              rightBlockWidth,
              false,
              true
            )
          }

          if (showEmptyFields || treatmentDiary.research_result) {
            pdfCurrentPositionY = addTextWithMultiRows(
              pdf,
              pdfCurrentPositionY,
              pdfRowHeight,
              pdfMargin,
              'Результаты исследования:',
              treatmentDiary.research_result ? treatmentDiary.research_result : '',
              rightBlockWidth,
              false,
              true
            )
          }

          const teethNumbers = []
          treatmentDiary.status_teeth.map(t => t.teeth ? teethNumbers.push(t.teeth.number) : null)
          pdfCurrentPositionY = addTextWithMultiRows(
            pdf, pdfCurrentPositionY, pdfRowHeight, pdfMargin, 'Зуб(ы):', teethNumbers.join(', '), rightBlockWidth,
            false, true
          )
        }

        pdfCurrentPositionY = addTextWithMultiRows(
          pdf,
          pdfCurrentPositionY,
          pdfRowHeight,
          pdfMargin,
          'Диагноз:',
          getDiagnosisCustomName(treatmentDiary) + '',
          rightBlockWidth,
          false,
          true
        )

        if (treatmentDiary.type === 'DENTAL' && (showEmptyFields || treatmentDiary.description_diagnosis)) {
          pdfCurrentPositionY = addTextWithMultiRows(
            pdf,
            pdfCurrentPositionY,
            pdfRowHeight,
            pdfMargin,
            'Описание диагноза:',
            treatmentDiary.description_diagnosis ? treatmentDiary.description_diagnosis : '',
            rightBlockWidth,
            false,
            true
          )
        }

        pdfCurrentPositionY = addTextWithMultiRows(
          pdf, pdfCurrentPositionY, pdfRowHeight, pdfMargin, 'Лечение:', treatmentMedicalHistory, rightBlockWidth,
          false, true
        )

        pdf.line(savedPdfMargin, diaryContentLineTop, savedPdfMargin, pdfCurrentPositionY)
        pdf.line(pdfWidth - savedPdfMargin, diaryContentLineTop, pdfWidth - savedPdfMargin, pdfCurrentPositionY)
        pdf.line(
          pdfWidth - (savedPdfMargin + rightBlockWidth), diaryContentLineTop,
          pdfWidth - (savedPdfMargin + rightBlockWidth), pdfCurrentPositionY
        )
        pdf.line(savedPdfMargin, pdfCurrentPositionY, pdfWidth - savedPdfMargin, pdfCurrentPositionY,)

        // Right block Doctor name
        pdf.setFont(pdfFontNormal)
        pdf.text(
          pdf.splitTextToSize(
            treatmentDiary.doctor ? treatmentDiary.doctor.full_name : '',
            rightBlockWidth - diaryPaddingX
          ),
          pdfWidth - pdfMargin - (rightBlockWidth / 2),
          pdfCurrentPositionY - ((pdfCurrentPositionY - tableHeaderBottomLinepPosition) / 2),
          'center'
        )

        pdf.save(`${fileName} - ` + patient.full_name + `${treatmentDate ? ` (${treatmentDate})` : ''}.pdf`)
      })
  }
}

export const createAppointmentTalon = async appointment => {
  let items = []
  if (appointment.service_time_selected_complexes && appointment.service_time_selected_complexes.length) {
    items = appointment.service_time_selected_complexes.map(i => ({...i, count: 1}))
  }
  if (appointment.service_time_selected_services && appointment.service_time_selected_services.length) {
    items = appointment.service_time_selected_services.map(i => ({...i, count: 1}))
  }
  const { getPatientDiscountCardByCardId } = usePatient()
  const discountCard = appointment.patient.discount_card_id
    ? getPatientDiscountCardByCardId(appointment.patient.discount_card_id) : null
  const discountPercent = discountCard ? discountCard.percent : 0
  const errorTitle = 'Ошибка формирования талона'
  const { getCurrentIntegrator } = useIntegrator()
  const integrator = await getCurrentIntegrator().then(({ data }) => data)
  await createCashReceipt(
    appointment.id, 'Талон ', appointment.start, appointment.patient, appointment.doctor, integrator, items,
    discountPercent, [], errorTitle
  )
}

export const createTreatmentDiaryCashReceipt = async (
  treatmentDiary, patient, discountPercent, paymentMethods = []
) => {
  const { getTreatmentDiary } = useTreatmentDiary()
  const info = await getTreatmentDiary(treatmentDiary.id).then(({ data }) => data)
  const errorTitle = 'Ошибка формирования кассового чека'
  if (!info) {
    showCommonToast(errorTitle, 'Пожалуйста, обратитесь в службу технической поддержки', 'danger')
  }
  let items = []
  if (info.dental_services_history && info.dental_services_history.length) {
    items = info.dental_services_history.map(s => ({
      name: s.name,
      price: s.total_price,
      count: s.count
    }))
  }
  await createCashReceipt(
    info.act_number, 'Кассовый чек по акту №', info.date, info.patient, treatmentDiary.doctor,
    info.patient.integrator, items, discountPercent, paymentMethods, errorTitle
  )
}

const createCashReceipt = async (
  docNumber, docLabel, date, patient, doctor, integrator, positions, discountPercent, paymentMethods, errorTitle
) => {
  if (!docNumber || !patient || !doctor || !integrator) {
    showCommonToast(errorTitle, 'Пожалуйста, обратитесь в службу технической поддержки', 'danger')
    return
  }
  let marginTop = 10
  let margin = 6
  let doc = new jsPDF({
    orientation: 'portrait', unit: 'mm', format: [58, 297], putOnlyUsedFonts: true
  })
  let y = marginTop
  const pdfFontBold = 'OpenSans-Bold'
  const pdfFontNormal = 'OpenSans-Regular'
  const width = doc.internal.pageSize.width
  const height = doc.internal.pageSize.height
  const scaleFactor = doc.internal.scaleFactor
  const offset = 2
  const lineHeightFactor = doc.getLineHeightFactor()

  let text = integrator.name
  let textMaxWidth = width - ((margin * 2))
  doc.setFont(pdfFontBold)
  doc.setFontSize(10)
  let textLines = doc.splitTextToSize(text, textMaxWidth)
  doc.text(textLines, (width / 2), y, 'center')

  doc.setFont(pdfFontNormal)
  doc.setFontSize(8.8)
  y += (doc.getLineHeight(textLines[0]) / scaleFactor) * textLines.length
  doc.setLineDashPattern([1, 1], 0)
  doc.line(margin, y, width, y, 'DF')

  y += (lineHeightFactor * 2) + offset
  text = 'Адрес:'
  doc.text(text, margin + 1, y)
  textMaxWidth = width / 2 - 2
  const address = integrator.address ? integrator.address : ''
  textLines = doc.splitTextToSize(address, textMaxWidth)
  doc.text(textLines, width - 2, y, { align: 'right' })

  y += (doc.getLineHeight(textLines[0]) / scaleFactor) * textLines.length
  text = 'Документ:'
  doc.text(text, margin + 1, y)
  doc.text(docNumber.toString(), width - 2, y, { align: 'right' })

  y += doc.getLineHeight(text) / scaleFactor
  text = 'Дата визита:'
  doc.text(text, margin + 1, y)
  doc.text(mixins.methods.formatDateAsDayMonthYearTime(date), width - 2, y, { align: 'right' })

  y += doc.getLineHeight(text) / scaleFactor / 2
  doc.setLineDashPattern([1, 1], 0)
  doc.line(margin, y, width, y, 'DF')

  y += (lineHeightFactor * 2) + offset
  text = 'Клиент:'
  doc.text(text, margin + 1, y)
  const patientFullName = patient.full_name ? patient.full_name : ''
  textLines = doc.splitTextToSize(patientFullName, textMaxWidth)
  doc.text(textLines, width - 2, y, { align: 'right' })

  y += (doc.getLineHeight(textLines[0]) / scaleFactor) * textLines.length
  text = 'Телефон:'
  doc.text(text, margin + 1, y)
  const patientPhone = patient.full_phone ? patient.full_phone.replace(' ', '') : ''
  doc.text(patientPhone, width - 2, y, { align: 'right' })

  y += doc.getLineHeight(text) / scaleFactor/ 2
  doc.setLineDashPattern([1, 1], 0)
  doc.line(margin, y, width, y, 'DF')

  y += (lineHeightFactor * 2) + offset
  text = 'Сотрудник:'
  doc.text(text, margin + 1, y)
  const doctorFullName = doctor.full_name ? doctor.full_name : ''
  textLines = doc.splitTextToSize(doctorFullName, textMaxWidth)
  doc.text(textLines, width - 2, y, { align: 'right' })

  y += (doc.getLineHeight(textLines[0]) / scaleFactor) * textLines.length - (doc.getLineHeight(textLines[0]) / scaleFactor / 2)
  doc.setLineDashPattern([1, 1], 0)
  doc.line(margin, y, width, y, 'DF')

  y += (lineHeightFactor * 2) + offset
  text = 'Дата:'
  doc.text(text, margin + 1, y)
  doc.text(moment().format('DD.MM.YYYY HH:mm'), width - 2, y, { align: 'right' })

  let totalCount = 0
  if (positions.length) {
    y += doc.getLineHeight(text) / scaleFactor/ 2
    doc.setLineDashPattern([1, 1], 0)
    doc.line(margin, y, width, y, 'DF')

    y += (lineHeightFactor * 2) + offset
    let sum = 0, discountSum = 0, totalSum = 0
    positions.forEach(i => {
      const name = i.name ? i.name : ''
      const price = i.price ? i.price : 0
      const count = i.count ? i.count : 0
      const totalServicePrice = count * i.price
      const discountPrice = totalServicePrice && discountPercent
        ? parseFloat(mixins.methods.formatPrice(((totalServicePrice / 100) * discountPercent), false))
        : 0
      const totalPrice = parseFloat(
        mixins.methods.formatPrice(totalServicePrice - discountPrice, false)
      )
      const iRows = [
        {label: name, value: null, font: pdfFontBold},
        {label: 'Цена:', value: mixins.methods.formatPrice(price, true), font: pdfFontNormal},
        {label: 'Количество:', value: count.toString(), font: pdfFontNormal},
        {
          label: 'Скидка:', value: mixins.methods.formatPrice(discountPrice, true),
          font: pdfFontNormal
        },
        {label: 'Итого:', value: mixins.methods.formatPrice(totalPrice, true), font: pdfFontNormal},
      ]
      iRows.forEach((r, idx) => {
        const rItemStep = (lineHeightFactor * 2) + offset
        let rTitleStep = 0
        doc.setFont(r.font)
        if (!idx) {
          textMaxWidth = width - margin - offset - 1
          textLines = doc.splitTextToSize(r.label, textMaxWidth)
          rTitleStep = (doc.getLineHeight(textLines[0]) / scaleFactor) * textLines.length
          const blockHeight = (4 * rItemStep) + rTitleStep
          if (y + blockHeight > height - marginTop) {
            doc.addPage()
            y = marginTop
          }
        }
        if (idx) {
          doc.text(r.label, margin + 1, y)
          doc.text(r.value, width - 2, y, { align: 'right' })
          y += rItemStep
        } else {
          doc.text(textLines, margin + 1, y)
          y += rTitleStep
        }
      })

      sum += price
      discountSum += discountPrice
      totalSum += totalPrice
      totalCount += count
    })

    const tRows = [
      {label: 'Итого', value: null, font: pdfFontBold},
      {label: 'Цена:', value: mixins.methods.formatPrice(sum, true), font: pdfFontNormal},
      {label: 'Количество:', value: totalCount.toString(), font: pdfFontNormal},
      {
        label: 'Скидка:', value: mixins.methods.formatPrice(discountSum, true),
        font: pdfFontNormal
      },
      {label: 'Итого:', value: mixins.methods.formatPrice(totalSum, true), font: pdfFontNormal},
    ]

    tRows.forEach((r, idx) => {
      if (!idx) {
        const rStep = (lineHeightFactor * 2) + offset
        const blockHeight = 5 * rStep
        if (y + blockHeight > height - marginTop) {
          doc.addPage()
          y = marginTop
        }
      }
      doc.setFont(r.font)
      doc.text(r.label, margin + 1, y)
      if (r.value !== null) {
        doc.text(r.value, width - 2, y, { align: 'right' })
      }
      y += (lineHeightFactor * 2) + offset
    })
  }

  if (paymentMethods && paymentMethods.length) {
    y -= doc.getLineHeight(text) / scaleFactor/ 2
    doc.setLineDashPattern([1, 1], 0)
    doc.line(margin, y, width, y, 'DF')

    y += (lineHeightFactor * 2) + offset
    text = 'Способы оплаты:'
    textMaxWidth = width - margin - offset - 1
    doc.setFont(pdfFontBold)
    textLines = doc.splitTextToSize(text, textMaxWidth)
    let rTitleStep = (doc.getLineHeight(textLines[0]) / scaleFactor) * textLines.length
    let blockHeight = (lineHeightFactor * 2) + offset + rTitleStep
    if (y + blockHeight > height - marginTop) {
      doc.addPage()
      y = marginTop
    }
    doc.text(text, margin + 1, y)

    doc.setFont(pdfFontNormal)
    y += (lineHeightFactor * 2) + offset
    let sum = 0, discountSum = 0, totalSum = 0
    paymentMethods.forEach(i => {
      const name = i.payment_method.name
      const amount = i.paid_amount
      const rItemStep = (lineHeightFactor * 2) + offset
      let rTitleStep = 0
      textMaxWidth = width - margin - offset - 1
      textLines = doc.splitTextToSize(`${name}:`, textMaxWidth)
      rTitleStep = (doc.getLineHeight(textLines[0]) / scaleFactor) * textLines.length
      const blockHeight = rItemStep + rTitleStep
      if (y + blockHeight > height - marginTop) {
        doc.addPage()
        y = marginTop
      }
      doc.text(textLines, margin + 1, y)
      doc.text(mixins.methods.formatPrice(amount, true), width - 2, y, { align: 'right' })
      y += rTitleStep
      sum += amount
    })

    text = 'Итого:'
    rTitleStep = doc.getLineHeight(text) / scaleFactor / 2
    blockHeight = (lineHeightFactor * 2) + offset + rTitleStep
    if (y + blockHeight > height - marginTop) {
      doc.addPage()
      y = marginTop
    }
    doc.text(text, margin + 1, y)
    doc.text(mixins.methods.formatPrice(sum, true), width - 2, y, { align: 'right' })
  }
  doc.save(`${docLabel}${docNumber}.pdf`)
}

export const createGeneralMedicalCard = (
  cardNumber, fullName, birthday, gender, documentIdentifier, workPlace, phone, integratorName, integratorAddress
) => {

  let doc = new jsPDF({
    orientation: 'landscape', unit: 'mm', format: [210, 297], putOnlyUsedFonts: true
  })
  const pdfFont = 'Times-Roman'
  const pdfFontBold = 'bold'
  const pdfFontNormal = 'normal'
  const docWidth = doc.internal.pageSize.width
  const docMarginLeft = 25
  const docMarginRight = 20
  const lineHeightFactor = doc.getLineHeightFactor()

  let y = 10
  doc.setFont(pdfFont, pdfFontNormal)
  doc.setFontSize(10)
  textItems = ['(наименование организации здравоохранения)', '(структурного (обособленного) подразделения)']
  let textWidth = doc.getTextWidth(integratorName)
  let minWidth = doc.getTextWidth(textItems[1])
  let offset = (textWidth >= minWidth ? 0 : minWidth - textWidth) / 2
  const maxTextXPosition = docMarginLeft + (minWidth > textWidth ? minWidth : textWidth)
  doc.text(integratorName, docMarginLeft + offset, y)
  doc.line(docMarginLeft, y + 1, maxTextXPosition, y + 1)
  y += doc.getLineHeight(integratorName) / doc.internal.scaleFactor

  textItems.forEach(item => {
    const width = doc.getTextWidth(item)
    offset = (textWidth >= minWidth ? textWidth - width : minWidth - width) / 2
    doc.text(item, docMarginLeft + offset, y)
    itemHeight = doc.getLineHeight(item) / doc.internal.scaleFactor
    y += itemHeight
  })

  y = 10
  doc.setFontSize(11)
  let textRightOffset = 53
  let textItems = [
    'Приложение', 'к постановлению', 'Министерства здравоохранения', 'Республики Беларусь', '05.12.2023 № 188'
  ]
  let itemHeight = 0
  textItems.forEach(item => {
    doc.text(item, docWidth - docMarginRight - textRightOffset, y)
    itemHeight = doc.getLineHeight(item) / doc.internal.scaleFactor
    y += itemHeight
  })

  y += itemHeight
  let text = 'Форма 025/у-23'
  textRightOffset = 26
  doc.text(text, docWidth - docMarginRight - textRightOffset, y)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  doc.setFont(pdfFont, pdfFontBold)
  doc.setFontSize(12)
  textItems = ['МЕДИЦИНСКАЯ КАРТА ПАЦИЕНТА', 'ДЛЯ АМБУЛАТОРНО-ПОЛИКЛИНИЧЕСКИХ ОРГАНИЗАЦИЙ']
  textItems.forEach(item => {
    doc.text(item, (docWidth / 2) + ((docMarginLeft - docMarginRight) / 2), y, 'center')
    itemHeight = doc.getLineHeight(item) / doc.internal.scaleFactor
    y += itemHeight
  })

  doc.setFont(pdfFont, pdfFontNormal)
  const centerX = (docWidth / 2) + ((docMarginLeft - docMarginRight) / 2)
  const cardNumberWidth = doc.getTextWidth(cardNumber)
  text = '№'
  doc.text(text, centerX, y)
  const cardNumberX = centerX + doc.getTextWidth(text) + 1 + ((25 - cardNumberWidth) / 2)
  doc.text(cardNumber, cardNumberX, y)
  doc.line(
    centerX + doc.getTextWidth(text) + 1, y + 1, centerX + doc.getTextWidth(text) + 25, y + 1
  )
  y += (doc.getLineHeight(text) / doc.internal.scaleFactor) * 2

  let prevText = 'Фамилия, собственное имя, отчество (если таковое имеется)'
  doc.text(prevText, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(prevText) + 1
  doc.text(
    fullName, textWidth + ((docWidth - docMarginRight - textWidth - doc.getTextWidth(fullName)) / 2), y
  )
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  // doc.setLineHeightFactor(lineHeightFactor)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  const birthdayParts = birthday ? moment(birthday).format('LL').split(' ') : []
  text = 'Число, месяц, год рождения'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  text = birthdayParts.length ? birthdayParts[0] : ''
  doc.text(text, textWidth + ((7 - doc.getTextWidth(birthdayParts[0])) / 2), y)
  doc.line(textWidth, y + 1, textWidth + 7, y + 1)
  textWidth = textWidth + 8
  text = birthdayParts.length ? birthdayParts[1] : ''
  doc.text(text, textWidth + ((30 - doc.getTextWidth(birthdayParts[1])) / 2), y)
  doc.line(textWidth, y + 1, textWidth + 30, y + 1)
  textWidth = textWidth + 31
  text = birthdayParts.length ? birthdayParts[2] : ''
  doc.text(text, textWidth + ((9 - doc.getTextWidth(birthdayParts[2])) / 2), y)
  doc.line(textWidth, y + 1, textWidth + 9, y + 1)
  textWidth = textWidth + 10
  text = 'г.'
  doc.text(text, textWidth, y)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'Идентификационный номер пациента:'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  documentIdentifier = documentIdentifier ? documentIdentifier : ''
  doc.text(
    documentIdentifier,
    textWidth + ((docWidth - docMarginRight - textWidth - doc.getTextWidth(documentIdentifier)) / 2),
    y
  )
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'Пол: мужской, женский (нужное подчеркнуть).'
  if ([0, 1].includes(gender)) {
    if (gender === 0) {
      doc.line(docMarginLeft + 9.5, y + 1, docMarginLeft + 25.8, y + 1)
    } else {
      doc.line(docMarginLeft + 27.3, y + 1, docMarginLeft + 43.2, y + 1)
    }
  }
  doc.text(text, docMarginLeft, y)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'Место жительства (место пребывания): область'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'район'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 49, y + 1)
  textWidth = textWidth + 50
  text = 'населенный пункт'
  doc.text(text, textWidth, y)
  textWidth = textWidth + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'улица/проспект/переулок/проезд'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'дом'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 31, y + 1)
  textWidth = textWidth + 31
  text = ', корпус'
  doc.text(text, textWidth, y)
  textWidth = textWidth + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 31, y + 1)
  textWidth = textWidth + 31
  text = ', квартира'
  doc.text(text, textWidth, y)
  textWidth = textWidth + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'Адрес фактического проживания: область'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'район'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 49, y + 1)
  textWidth = textWidth + 50
  text = 'населенный пункт'
  doc.text(text, textWidth, y)
  textWidth = textWidth + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'улица/проспект/переулок/проезд'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'дом'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 31, y + 1)
  textWidth = textWidth + 31
  text = ', корпус'
  doc.text(text, textWidth, y)
  textWidth = textWidth + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 31, y + 1)
  textWidth = textWidth + 31
  text = ', квартира'
  doc.text(text, textWidth, y)
  textWidth = textWidth + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'Место работы (учебы), должность служащего'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  workPlace = workPlace ? workPlace : ''
  doc.text(workPlace, textWidth + ((docWidth - docMarginRight - textWidth - doc.getTextWidth(workPlace)) / 2), y)
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'Телефон: домашний'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 31, y + 1)
  textWidth = textWidth + 31
  text = ', мобильный'
  doc.text(text, textWidth, y)
  textWidth = textWidth + doc.getTextWidth(text) + 1
  phone = phone ? phone : ''
  doc.text(phone, textWidth + ((docWidth - docMarginRight - textWidth - doc.getTextWidth(phone)) / 2), y)
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = 'Категория граждан, имеющих льготы на медицинское обслуживание (указать льготу)'
  doc.text(text, docMarginLeft, y)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor
  doc.line(docMarginLeft, y + 1, docWidth - docMarginRight, y + 1)
  y += (doc.getLineHeight(text) / doc.internal.scaleFactor) * 2

  const currentDateParts = moment().format('LL').split(' ')
  text = currentDateParts.length ? currentDateParts[0] : ''
  doc.text(text, docMarginLeft + ((8 - doc.getTextWidth(currentDateParts[0])) / 2), y)
  doc.line(docMarginLeft, y + 1, docMarginLeft + 8, y + 1)
  textWidth = docMarginLeft + 9
  text = currentDateParts.length ? currentDateParts[1] : ''
  doc.text(text, textWidth + ((50 - doc.getTextWidth(currentDateParts[1])) / 2), y)
  doc.line(textWidth, y + 1, textWidth + 50, y + 1)
  textWidth = textWidth + 51
  text = currentDateParts.length ? currentDateParts[2] : ''
  doc.text(
    text.replace(text.substring(0, 2), `${text.substring(0, 2)} `),
    textWidth + 1 + ((6 - doc.getTextWidth(currentDateParts[2])) / 2),
    y
  )
  textWidth += doc.getTextWidth(text.substring(0, 2))
  doc.line(textWidth, y + 1, textWidth + 6, y + 1)
  textWidth = textWidth + 7
  text = 'г.'
  doc.text(text, textWidth, y)

  text = '(дата оформления медицинской карты пациента)'
  doc.setFontSize(10)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor
  doc.text(text, docMarginLeft, y)

  // page 2
  doc.addPage()
  y = 10
  doc.setFontSize(12)
  doc.setFont(pdfFont, pdfFontBold)
  text = 'Медицинский осмотр врача-специалиста'
  doc.text(text, (docWidth / 2) + ((docMarginLeft - docMarginRight) / 2), y, 'center')
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  doc.setFont(pdfFont, pdfFontNormal)
  text = 'первичный, повторный, активный (нужное подчеркнуть)'
  doc.text(text, (docWidth / 2) + ((docMarginLeft - docMarginRight) / 2), y, 'center')
  y += (doc.getLineHeight(text) / doc.internal.scaleFactor) * 2

  const paragraphOffset = 10
  text = '1. Место проведения медицинского осмотра:'
  doc.text(text, docMarginLeft + paragraphOffset, y)
  textWidth = docMarginLeft + paragraphOffset + doc.getTextWidth(text) + 1
  integratorAddress = integratorAddress ? integratorAddress : ''
  doc.text(
    integratorAddress,
    textWidth + ((docWidth - docMarginRight - textWidth - doc.getTextWidth(integratorAddress)) / 2),
    y
  )
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = '2. Дата и время проведения медицинского осмотра:'
  doc.text(text, docMarginLeft + paragraphOffset, y)
  textWidth = docMarginLeft + paragraphOffset + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 4, y + 1)
  textWidth += 5
  doc.line(textWidth, y + 1, textWidth + 16, y + 1)
  textWidth += 17
  text = '20'
  doc.text(text, textWidth, y)
  textWidth += doc.getTextWidth(text)
  doc.line(textWidth, y + 1, textWidth + 4, y + 1)
  textWidth += 5
  text = 'г.,'
  doc.text(text, textWidth, y)
  textWidth += doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 4, y + 1)
  textWidth += 5
  text = 'час'
  doc.text(text, textWidth, y)
  textWidth += doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 4, y + 1)
  textWidth += 5
  text = 'мин.'
  doc.text(text, textWidth, y)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = '3. Жалобы пациента:'
  doc.text(text, docMarginLeft + paragraphOffset, y)
  textWidth = docMarginLeft + paragraphOffset + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor
  doc.line(docMarginLeft, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = '4. Анамнез заболевания:'
  doc.text(text, docMarginLeft + paragraphOffset, y)
  textWidth = docMarginLeft + paragraphOffset + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = '5. Данные результатов медицинского осмотра:'
  doc.text(text, docMarginLeft + paragraphOffset, y)
  textWidth = docMarginLeft + paragraphOffset + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor
  doc.line(docMarginLeft, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = '6. Диагноз: предварительный, заключительный клинико-функциональный (нужное'
  doc.text(text, docMarginLeft + paragraphOffset, y)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor
  text = 'подчеркнуть)'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor
  doc.line(docMarginLeft, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor
  doc.line(docMarginLeft, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor
  doc.line(docMarginLeft, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = '7. Лечение:'
  doc.text(text, docMarginLeft + paragraphOffset, y)
  textWidth = docMarginLeft + paragraphOffset + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor
  doc.line(docMarginLeft, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = '8. Рекомендации:'
  doc.text(text, docMarginLeft + paragraphOffset, y)
  textWidth = docMarginLeft + paragraphOffset + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor
  doc.line(docMarginLeft, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = '9. Экспертиза временной нетрудоспособности:'
  doc.text(text, docMarginLeft + paragraphOffset, y)
  textWidth = docMarginLeft + paragraphOffset + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor
  doc.line(docMarginLeft, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = '10. Листок нетрудоспособности (справка о временной нетрудоспособности)'
  doc.text(text, docMarginLeft + paragraphOffset, y)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = '№'
  doc.text(text, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 17, y + 1)
  textWidth += 18
  text = 'с'
  doc.text(text, textWidth, y)
  textWidth += doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 17, y + 1)
  textWidth += 18
  text = 'по'
  doc.text(text, textWidth, y)
  textWidth += doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 17, y + 1)
  textWidth += 18
  text = 'режим'
  doc.text(text, textWidth, y)
  textWidth += doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 17, y + 1)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor

  text = '11. Дата повторного медицинского осмотра:'
  doc.text(text, docMarginLeft + paragraphOffset, y)
  textWidth = docMarginLeft + paragraphOffset + doc.getTextWidth(text) + 1
  doc.line(textWidth, y + 1, textWidth + 8, y + 1)
  textWidth += 9
  doc.line(textWidth, y + 1, textWidth + 30, y + 1)
  textWidth += 31
  text = '20'
  doc.text(text, textWidth, y)
  textWidth += doc.getTextWidth(text)
  doc.line(textWidth, y + 1, textWidth + 4, y + 1)
  textWidth += 5
  text = 'г.'
  doc.text(text, textWidth, y)
  y += (doc.getLineHeight(text) / doc.internal.scaleFactor) * 2

  const mainText = 'Врач-специалист'
  doc.text(mainText, docMarginLeft, y)
  textWidth = docMarginLeft + doc.getTextWidth(mainText) + 5
  doc.line(textWidth, y + 1, textWidth + 32, y + 1)
  textWidth += 37
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(mainText) / doc.internal.scaleFactor
  doc.setFontSize(10)
  text = '(подпись)'
  doc.text(text, 69.5, y)
  text = '(инициалы (инициал собственного имени), фамилия)'
  doc.text(text, 104, y)
  y += (doc.getLineHeight(text) / doc.internal.scaleFactor) * 2

  doc.setFontSize(12)
  text = 'Согласие на проведение простых медицинских вмешательств получено.'
  doc.text(text, docMarginLeft, y)
  y += doc.getLineHeight(text) / doc.internal.scaleFactor
  textWidth -= 37
  doc.line(textWidth, y + 1, textWidth + 32, y + 1)
  textWidth += 37
  doc.line(textWidth, y + 1, docWidth - docMarginRight, y + 1)
  y += doc.getLineHeight(mainText) / doc.internal.scaleFactor
  doc.setFontSize(10)
  text = '(подпись)'
  doc.text(text, 69.5, y)
  text = '(инициалы (инициал собственного имени), фамилия)'
  doc.text(text, 104, y)

  doc.save(`Общая медицинская карта.pdf`)
}

const addTextWithMultiRows = (
  pdf, posY, pdfRowHeight, pdfMargin, label, text = '', marginRight = 0, withLine = true,
  labelBold = false
) => {
  const pdfFontBold = 'OpenSans-Bold'
  const pdfFontNormal = 'OpenSans-Regular'
  const pdfWidth = 210
  label = label ? label + ' ' : ''
  text = text === null ? '' : text
  if (labelBold) {
    pdf.setFont(pdfFontBold)
  }
  const labelTextWidth = pdfMixins.methods.getTextWidth(pdf, label)

  pdf.text(label, pdfMargin, posY)

  if (labelBold) {
    pdf.setFont(pdfFontNormal)
  }

  let lines = pdf.splitTextToSize(
    text,
    (pdfWidth - (((pdfMargin * 2) + labelTextWidth))) - marginRight
  )
  let firstLine = ''
  if (lines.length > 0) {
    firstLine = lines[0]
  }
  text = text.slice(firstLine.length)
  lines = text === ''
    ? []
    : pdf.splitTextToSize(
      text,
      (pdfWidth - (pdfMargin * 2)) - marginRight
    )
  pdf.text(firstLine, pdfMargin + labelTextWidth, posY)
  if (withLine) {
    posY += 1
    pdf.line(pdfMargin + labelTextWidth, posY, (pdfWidth - pdfMargin) - marginRight, posY)
  }

  posY += pdfRowHeight

  lines.forEach(line => {
    pdf.text(line.trim(), pdfMargin, posY)
    if (withLine) {
      posY += 1
      pdf.line(pdfMargin, posY, (pdfWidth - pdfMargin) - marginRight, posY)
    }
    posY += pdfRowHeight
  })

  return posY
}

const getDiagnosisCustomName = diary => diary.diagnosis_custom_name
  ? diary.diagnosis_custom_name : getDiagnosesNames(diary)

const getDiagnosesNames = diary => diary.diagnoses.map(d => d.full_name).join(', ')

export const fullNameToInitials = fullName => {
  if (fullName) {
    fullName = fullName ? fullName.split(' ') : ''
    if (fullName) {
      return fullName.length === 3
        ? `${mixins.methods.capitalizeFirstLetter(fullName[1][0])}.${mixins.methods.capitalizeFirstLetter(fullName[1][0])}. ${fullName[0]}`
        : fullName.join(' ')
    }
  }
  return fullName ? fullName : ''
}

const getTextWidth = (pdf, text) => pdfMixins.methods.getTextWidth(pdf, text)

const getServicesTableTitleForActOfServicesRendered = text =>
  pdfMixins.methods.getServicesTableTitleForActOfServicesRendered(text)
