import appConstants from '@/constants/constants'

export default {
  bitesList: [],
  discountCardsList: [],
  paymentMethods: [],
  contacts: [],
  employee: [],
  employeeByClinicPerPage: [],
  employeeByClinicPerPageCount: 0,
  roles: [],
  permissions: [],
  user_permissions: [],
  units: [],
  nds: [],
  advisoryOpinions: [],
  advisoryOpinionsCount: 0,
  lotWithMaterialItems: [],
  lotWithMaterialItemsCount: 0,
  diagnosisGroupsList: [],
  diagnosis: [],
  searchTemplates: [],
  searchTemplatesCount: 0,
  plansList: [],
  plansCount: 0,
  patientCardTemplateMainGroups: [],
  patientCardTemplateGroups: [],
  servicesGroupsMain: [],
  servicesGroups: [],
  servicesGroupsAll: [],
  services: [],
  diagnosisGroupsMain: [],
  diagnosisSubGroups: [],
  diagnosisGroupsAll: [],
  materialGroups: [],
  materialsPerPage: [],
  materialsPerPageCount: 0,
  materialLotsPerPage: [],
  materialLotsPerPageCount: 0,
  materialLotTypes: [
    {value: 'medicinal-products', text: 'Лекарственные средства'},
    {value: 'medical-devices', text: 'Изделия медицинского назначения'},
    {value: 'medical-equipment', text: 'Медицинская техника'},
    {value: 'usual-product', text: 'Обычное изделие'},
  ],
  servicesCount: 0,
  diagnosisCount: 0,
  planToEdit: null,
  abilities: [],
  integrators: [],
  reserves: [],
  integrator: null,
  integratorSettings: null,
  reserve: null,
  filter_template: {},
  selected_diagnosis: [],
  loadingDoctors: null,
  integratorsCount: 0,
  serviceTimeCount: 0,
  serviceTimes: [],
  patientTypes: [],
  patientTypesCount: 0,
  patientType: null,
  serviceTime: null,
  contactsFromList: [],
  contactFrom: null,
  paymentMethodExtendedCategories: [],
  paymentDirections: [],
  paymentDirectionTypes: [],
  serviceTypes: [],
  insuranceCompanies: [],
  insuranceCompaniesCount: 0,
  notificationConditionCategoriesPerPage: [],
  notificationConditionCategoriesTotalCount: 0,
  notificationConditionCategories: [],
  legalPersons: [],
  legalPersonsCount: 0,
  thirdPartyOrganizations: [],
  thirdPartyOrganizationsCount: 0,
  patientAppealResultsPerPage: [],
  patientAppealResultsCount: 0,
  patientAppealResults: [],
  physicianProtocolFields: [],
  physicianProtocolFieldValues: [],
  physicianProtocolSections: [],
  physicianProtocolSubsections: [],
  physicianProtocolPerPage: [],
  physicianProtocolPerPageCount: 0,
  analysesSynevoPerPage: [],
  analysesSynevoPerPageCount: 0,
  treatmentFeaturesPerPage: [],
  treatmentFeaturesPerPageCount: 0,
  treatmentFeaturesForDropdown: [],
  plansWithTimePerPage: [],
  plansWithTimePerPageCount: 0,
  dentalServicesWithTimePerPage: [],
  dentalServicesWithTimePerPageCount: 0,
  userServiceForTimePropertyPerPage: [],
  userServiceForTimePropertyPerPageCount: 0,
  userDentalServicePropertyPerPage: [],
  userDentalServicePropertyPerPageCount: 0,
  userTreatmentPlanPropertyPerPage: [],
  userTreatmentPlanPropertyPerPageCount: 0,
  userActivityLogItemsPerPage: [],
  userActivityLogItemsPerPageCount: 0,
  pricingTypeGroups: [],
  pricingTypeGroupItems: [],
  patientAppointmentStatusesPerPage: [],
  patientAppointmentStatusesPerPageCount: 0,
  patientAppointmentStatuses: [],
  timeZones: [
    {label: 'Калининград, Калининградская область, Россия (GMT+2)', timezone: 'Europe/Kaliningrad'},
    {label: 'Минск, Беларусь (GMT+3)', timezone: 'Europe/Minsk'},
    {label: 'Москва, Россия (GMT+3)', timezone: 'Europe/Moscow'},
  ],
  patientContactViewPasswordSendingMethods: [
    {label: 'Смс', method: 'sms'},
    {label: 'Email', method: 'email'},
  ],
  appointmentNotificationOnCustomDayOptions: [
    {value: 'yesterday', label: 'Вчера'},
    {value: 'today', label: 'Сегодня'},
    {value: 'tomorrow', label: 'Завтра'},
    {value: 'day-after-tomorrow', label: 'Послезавтра'},
  ],
  appointmentNotificationByLastAppointmentOptions: [
    {value: 'one-month-ago', label: '1 месяц назад'},
    {value: 'three-month-ago', label: '3 месяца назад'},
    {value: 'six-month-ago', label: '6 месяцев назад'},
  ],
  appointmentNotificationByGenderOptions: [
    {value: 'female', label: 'Женский'},
    {value: 'male', label: 'Мужской'},
  ],
  faqQuestions: [],
  medicalServiceTypePerPage: [],
  medicalServiceTypePerPageCount: 0,
  specialistTypes: [],
  specialistTypesPerPage: [],
  specialistTypesPerPageCount: 0,
  dentalServiceSpecialistTypePerPage: [],
  dentalServiceSpecialistTypePerPageCount: 0,
  materialPriceList: [],
  materialPriceListItems: [],
  materialPriceListItemsCount: 0,
  onlineAppointmentServiceTimeIntervals: [
    {value: 5, label: '5 минут'},
    {value: 10, label: '10 минут'},
    {value: 20, label: '20 минут'},
    {value: 30, label: '30 минут'},
    {value: 60, label: '60 минут'},
  ],
  integratorBranchesPerPage: [],
  integratorBranchesPerPageCount: 0,
  materialPriceCalcSystem: [
    {
      value: appConstants.material.materialPriceCalcSystem.OSN,
      label: appConstants.material.materialPriceCalcSystem.OSN_LABEL
    },
    {
      value: appConstants.material.materialPriceCalcSystem.USN,
      label: appConstants.material.materialPriceCalcSystem.USN_LABEL
    },
  ],
  integratorTypeOptions: [
    {
      value: appConstants.integrator.type.CLINIC,
      text: appConstants.integrator.type.CLINIC_LABEL
    },
    {
      value: appConstants.integrator.type.DENTAL_LABORATORY,
      text: appConstants.integrator.type.DENTAL_LABORATORY_LABEL
    },
    {
      value: appConstants.integrator.type.CLINIC_DENTAL_LABORATORY,
      text: appConstants.integrator.type.CLINIC_DENTAL_LABORATORY_LABEL
    }
  ],
  recommendedVisitTypes: [],
  recommendedVisitTypesCount: 0,
  specialistTypeWageDeductionsAmountPerPage: [],
  specialistTypeWageDeductionsAmountPerPageCount: 0,
  counterparties: [],
  counterpartiesCount: 0,
  teethStates: [],
  teethStatesCount: 0,
  toothStateServicesPerPage: [],
  toothStateServicesPerPageCount: 0,
  toothStateComplexesPerPage: [],
  toothStateComplexesPerPageCount: 0,
  servicesRecalculationProcessIsActive: false
}
