export default {
  docType: Object.freeze({
    ACT_ASOBA: 'act-asoba',
    ACT_BEL_WEB: 'act-bel-web',
    ACT_BEL_EKSIM_GARANT: 'act-bel-eksim-garant',
    ACT_BEL_GOS_STRAH: 'act-bel-gos-strah',
    ACT_NEFTESTRAH_1: 'act-neftestrah-1',
    ACT_NEFTESTRAH_2: 'act-neftestrah-2',
    ACT_UNI_DOCTOR: 'act-uni-doctor',
    ACT_PROM_TRANS_INVEST: 'act-prom-trans-invest',
    ACT_ACT_IMKLIVA_INSURANCE: 'act-imkliva-insurance',
  })
}
