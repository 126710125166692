export default {
  docType: Object.freeze({
    CONTRACT_ABOUT_PROVISION_OF_PAID_DENTAL_CARE: 'contract-about-provision-of-paid-dental-care',
    ACT_OF_SERVICES_RENDERED: 'act-of-services-rendered', PURCHASE_ORDER: 'purchase-order',
    ACT_OF_COMPLEX_SERVICES_RENDERED: 'act-of-complex-services-rendered',
    ACT_OF_SERVICES_RENDERED_SHORT: 'act-of-services-rendered-short',
    ACT_OF_SERVICES_RENDERED_WITH_MATERIALS: 'act-of-services-rendered-with-materials',
    STATEMENT_OF_WORK_PERFORMED_FOR_MEDICAL_SERVICES_RENDERED:
      'statement-of-work-performed-for-medical-services-rendered',
    ACCEPTANCE_ACT_OF_RENDERED_MEDICAL_SERVICES: 'acceptance-act-of-rendered-medical-services',
    ACT_OF_WORK_PERFORMED_WITH_REQUISITES: 'act-of-work-performed-with-requisites',
    TREATMENT_DIARY: 'treatment-diary',
    CASH_RECEIPT: 'cash-receipt',
  }),
  type: Object.freeze({DENTAL: 'DENTAL', MEDICAL: 'MEDICAL'})
}
