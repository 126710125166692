import { isNotEnoughMaterialByLot } from '@/composables/materialHelper'
import { mixins } from '@/mixins'
import { addDataToLocalStorageByPrefix, getDataFromLocalStorageByPrefix } from '@/composables/localStorageHelper'
import { showWarningOperationEternalToast } from '@/composables/toast'
import appConstants from '@/constants/constants'
import { integratorHasCalculationLogicWithoutCalcMaterialPrice } from '@/composables/priceCalculationHelper'

export const serviceHasNotEnoughMaterial = (service, count = 1) => {
  let has = false
  count = count ? parseFloat(count) : null
  if (
    count && mixins.methods.isProhibitZeroMaterialConsumption() && service.consumption_rate
    && service.consumption_rate.length
  ) {
    service.consumption_rate.forEach(rate => {
      const lot = rate.material_lots && rate.material_lots.length ? rate.material_lots[0] : null
      if (!has && isNotEnoughMaterialByLot(rate.part_rate * count, lot)) {
        has = true
      }
    })
  }
  return has
}

export const sortServicesByPositions = (services, localStorageKey) => {
  let servicesList = []
  if (services.length) {
    const positions = getDataFromLocalStorageByPrefix(localStorageKey)
    const excludedIds = []
    if (positions) {
      Object.keys(positions).map(k => {
        if (!services.find(s => s.id.toString() === k)) {
          excludedIds.push(k)
        }
      })
    }
    const result = []
    const newServices = []
    services.map(s => {
      if (positions && s.id.toString() in positions) {
        const oldPosition = positions[s.id]
        let counter = 0
        if (excludedIds) {
          counter = excludedIds.reduce((v, i) =>  oldPosition > positions[i] ? v + 1 : v, 0)
        }
        result[oldPosition - counter] = s
      } else {
        newServices.push(s)
      }
    })
    servicesList = result.concat(newServices)

    const data = {}
    servicesList.map((s, i) => data[s.id] = i)
    addDataToLocalStorageByPrefix(localStorageKey, data)
  } else {
    servicesList = []
  }
  return servicesList
}

export const notEnoughServicesMaterials = (materialId, lotId, services, showMessage = false) => {
  const info = {available: 0, need: 0, name: null}
  let notEnough = false
  services.map(s => {
    if (s.materials) {
      s.materials.map(m => {
        if (m.lot_id && m.lot_info && m.id === materialId && m.lot_id === lotId) {
          let materialExpendedNumber = m.lot_info.material_expended_number
            ? m.lot_info.material_expended_number : 0
          if (m.from_history && materialExpendedNumber) {
            materialExpendedNumber -= m.total_material_expended_number
          }
          const numberOfMaterial = m.lot_info.number_of_material ? m.lot_info.number_of_material : 0
          let diff = numberOfMaterial - materialExpendedNumber
          info.available = diff < 0 ? 0 : diff
          info.need += s.count * parseFloat(m.system_column_count ? m.system_column_count : m.part_rate)
          info.name = m.material_name
        }
      })
    }
  })
  const diff = info.available - info.need
  if (diff < 0) {
    if (showMessage) {
      const message = `Материал "${info.name}" доступен в количестве ${info.available}. 
        Вы запрашиваете ${info.need}. Уберите из услуги недостоющий материал, обнулите его количество, либо пополните
        его на складе.`
      showWarningOperationEternalToast(message, 'Недостаточно материала!')
    }
    notEnough = true
  }
  return notEnough
}

export const needRecalculateServicePrice = s => s && s.consumption_rate
  ? !!s.consumption_rate.find(cr => cr.recalculate_price_info && cr.recalculate_price_info.need_recalculate) : false

export const getServiceMaterialWarnings = s => {
  const warnings = []
  if (s && s.consumption_rate) {
    if (s.consumption_rate.find(cr => cr.recalculate_price_info && cr.recalculate_price_info.changed_price)) {
      let text = 'имеется материал с измененной ценой партии'
      if (!warnings.includes(text)) {
        warnings.push(text)
      }
    }
    if (s.consumption_rate.find(cr => cr.recalculate_price_info && cr.recalculate_price_info.not_enough_material)) {
      let text = 'имеется израсходованный материал'
      if (!warnings.includes(text)) {
        warnings.push(text)
      }
    }
    if (warnings.length === 1) {
      warnings[0] = mixins.methods.capitalizeFirstLetter(warnings[0])
    }
  }
  return warnings
}

export const calcServiceTotalPrice = s => {
  if (integratorHasCalculationLogicWithoutCalcMaterialPrice()) {
    return s.total_price
  }

  const nds = s.nds ? s.nds.value : null
  const servicePrice = s.service_price
  const ndsPrice = nds ? (servicePrice / 100) * nds : null
  const servicePriceWithNds = ndsPrice ? servicePrice + ndsPrice : servicePrice
  let totalMaterialsPrice = 0
  if (s.consumption_rate && s.consumption_rate.length) {
    let materials = []
    s.consumption_rate.map(cr => materials.push({
      ...cr.material, system_column_count: cr.part_rate,
      lot_info: cr.material_lots && cr.material_lots.length ? cr.material_lots[0] : null
    }))
    if (materials.length) {
      materials = parseMaterials(materials)
      totalMaterialsPrice = materials.length
        ? materials.reduce((val, i) => {
          let amount
          if (i.nds_value) {
            if (appConstants.material.materialPriceCalcSystem.USN === mixins.methods.getMaterialPriceCalcSystem()) {
              amount = i.price_per_unit
            } else {
              amount = (i.price + ((i.price / 100) * i.nds_value))
            }
          } else {
            amount = i.price
          }
          return val + (amount * i.system_column_count)
        }, 0)
        : 0
    }
  }
  return servicePriceWithNds + totalMaterialsPrice
}

const parseMaterials = materials => {
  let items = []
  materials.map(m => items = items.concat(getMaterialsFromLots(m)))
  return items
}

const getMaterialsFromLots = material => {
  const relatedWithServiceFromDict = true
  const fromHistory = false
  const materials = []
  let suitableLot = material.lots && material.lots.length ? material.lots[0] : null
  if (material.lot_info) {
    suitableLot = material.lot_info
  }
  if (suitableLot) {
    const lot = mixins.methods.getMaterialLotInfo(
      suitableLot, material.partial_consumption_of_material_unit, material.system_column_count
    )
    const item = {...material}
    item.nds_value = lot.nds
    item.related_with_service_from_dict = relatedWithServiceFromDict
    item.from_history = fromHistory
    item.price = lot.price_per_unit_without_nds
    item.system_column_count = lot.count
    item.lot_id = lot.id
    item.price_per_unit = lot.price_per_unit
    item.price_per_unit_without_nds = lot.price_per_unit_without_nds
    delete item.lots
    materials.push(item)
  }
  return materials
}
